/*eslint-disable*/
import { useEffect, useState } from "react";
import _ from "lodash";

import { useStorageListener, putStorage, useStorageValue } from "./useStorage";
import {LOCALES} from "../utils/locales";
//import dict from "../utils/dict";

const useDictionary = () => {
    const locales = Object.entries(LOCALES)
        .filter(item => item[0] !== 'Default')
        .map(item => {
            return item[1];
        });
    const _lang = localStorage.getItem(`lang`);
    const locale = _lang === 'null' || !_lang ? LOCALES.Default : _lang;
    const [lang, setLang] = useState(locale)

    const translate = useStorageListener(state => state?.i18nDict ?? {});

    const t = (key, resources, locale = lang) => {
        let answer = _.get(translate, `${key?.replace?.(`@`, ``)}`) ?? key;
        answer?.match?.(/{{ \w* }}|{{\w*}}/g)?.forEach?.((i) => {
            answer = answer.replaceAll(i, resources?.[i?.replaceAll?.(`{{`, ``)?.replaceAll?.(`}}`, ``)?.replaceAll?.(` `, ``)] ?? ``);
        });
        return answer;
    };

    return {
        lang,
        setLang,
        locales,
        //dict,
        t,
    };
};

export default useDictionary;
/*eslint-enable*/
