/*eslint-disable*/
import { useEffect, useState } from "react";
import _ from "lodash";

import { useStorageListener } from "./useStorage";

const usePageTitle = (additionalTitle) => {
    const title = useStorageListener((state) => state?.temp?.main?.title ?? `Intis`);
    useEffect(() => {
        document.title = `${title}${additionalTitle ? ` - ${additionalTitle}` : ``}`;
    }, [title, additionalTitle]);
};

export default usePageTitle;
/*eslint-enable*/