/*eslint-disable*/
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { isna, linkTo, queryParser } from "../utils/common-helper";
import { setToken } from "../utils/token-helper";

const useSpecialQueryParams = () => {
    const { search } = useLocation();
    useEffect(() => {
        const params = queryParser(search);
        if (!isna(params.token)) {
            setToken(params.token)
            linkTo(`/`)
            window.location.reload();
        }
    }, [search])
    return null
};

export default useSpecialQueryParams;
/*eslint-enable*/