/*eslint-disable*/
import { isna } from "./common-helper";
import {PROJECTS} from "./projects";

const appName = `intis`;
const localStorageKey = `auth_token_${appName}`;
const storage = PROJECTS.isSms2csclv() ? window.sessionStorage : window.localStorage;

const TokenHelper = {

    getToken() {
        let token = storage.getItem(localStorageKey);
        if (isna(token)) {
            return;
        }
        return token;
    },

    setToken(token) {
        storage.setItem(localStorageKey, token)
    },

    removeToken() {
        storage.removeItem(localStorageKey);
    },

};

export const { getToken, setToken, removeToken } = TokenHelper;

export default TokenHelper;
/*eslint-enable*/