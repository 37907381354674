/*eslint-disable*/
import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

import {putStorage} from '../hooks/useStorage'
import {GETOptions, handleError, handleSuccess, loadingCounterWrapper, POSTOptions} from '../utils/api-helper'
import {isna} from '../utils/common-helper';
import {removeToken, setToken} from '../utils/token-helper';

const UserAPI = {

    async getUserActionsLog(options) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.post(`${API_ENDPOINT}/user/pricelist-log`, POSTOptions(options))).data
                putStorage(`user.actionsLog`, response)
                // putStorage(`initialized`, true)
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async getCurrentUser() {
        return await loadingCounterWrapper(async () => {
            try {
                let user = (await axios.get(`${API_ENDPOINT}/user/info`)).data;
                putStorage(`user`, { ...user, userRole: user?.admin === true ? `admin` : `user` })
                putStorage(`initialized`, true)
                return user
            } catch (error) {
                putStorage(``, {})
                putStorage(`user`, { userRole: `guest` })
                putStorage(`initialized`, true)
                throw error
            }
        })
    },

    async authtorization(token, user_id) {
        return await loadingCounterWrapper(async () => {
            try {
                if (isna(token) && !isna(user_id)) {
                    // In case of two-factor auth
                    putStorage(`two_factor`, {user_id})
                    return user_id
                } else {
                    // Regular auth
                    setToken(token)
                    let user = await this.getCurrentUser()
                    return user
                }
            }catch(error) {
                throw handleError(error);
            }
        })
    },

    async login(email, password) {
        return await loadingCounterWrapper(async () => {
            try {

                let data = (await axios.post(`${API_ENDPOINT}/auth/login`, { email: email.toCase(`lower`), password: password })).data;
                let { token, user_id, isNeedConfirmationEmail} = data;

                if(isNeedConfirmationEmail) {
                    return { ...data };
                }

                if (isna(token) && !isna(user_id)) {
                    // In case of two-factor auth
                    putStorage(`two_factor`, { user_id })
                    return user_id
                }

                if(token) {
                    // Regular auth
                    setToken(token)
                    let user = await this.getCurrentUser()
                    return user
                }
                //console.log('123')
            } catch (error) {
                //console.log('login error -->', error, error.response.status)
                throw handleError(error)
            }
        })
    },

    async twoFactorAuth(code) {
        // TODO: this.getCurrentUser is undefined ???
        return await loadingCounterWrapper(async () => {
            try {
                let { token } = (await axios.post(API_ENDPOINT + `/auth/two-factor-auth`, { code: `` + code, user_id: `` + window?.storage?.two_factor?.user_id })).data;
                setToken(token)
                // this.getCurrentUser();
                let user = (await axios.get(`${API_ENDPOINT}/user/info`)).data
                putStorage(`user`, { ...user, userRole: `user` })
                putStorage(`initialized`, true)
                return
            } catch (error) {
                throw handleError(error)
            }
        })
    },

    async logout() {
        return await loadingCounterWrapper(async () => {
            try {
                removeToken();
                putStorage(``, {})
                putStorage(`user`, { userRole: `guest` })
            } catch (exc) {
                throw new Error(exc);
            }
        })
    },

    async signup(email, password, token) {
        return await loadingCounterWrapper(async () => {
            try {
                // let { token } = (await axios.post(`${API_ENDPOINT}/auth/signup`, { email: email.toCase(`lower`), password: password })).data
                // setToken(token)
                // let user = await this.getCurrentUser()
                // return user
                let signupData = (await axios.post(`${API_ENDPOINT}/auth/signup`, {
                    email: email.toCase(`lower`),
                    password: password,
                    token
                })).data;
                //let userData = {}//await this.getCurrentUser();
                return {signupData};
            } catch (error) {
                throw handleError(error)
            }
        })
    },

    async updateUser(data) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.put(`${API_ENDPOINT}/user`, data)).data
                await this.getCurrentUser()
                handleSuccess(response)
            } catch (error) {
                throw handleError(error)
            }
        })
    },

    async requestRecoveryCode(email) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.post(`${API_ENDPOINT}/auth/request-password-recovery`, { email })).data
                handleSuccess(response)
            } catch (error) {
                throw handleError(error)
            }
        })
    },

    async recoverPassword(email, password, code) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.post(`${API_ENDPOINT}/auth/recover-password`, { email, password, code })).data
                handleSuccess(response)
            } catch (error) {
                throw handleError(error)
            }
        })
    },

    async changePassword(old_password, new_password) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.put(`${API_ENDPOINT}/user/change-password`, { old_password, new_password })).data
                handleSuccess(response)
            } catch (error) {
                throw handleError(error)
            }
        })
    },

    async getAuthHistory(options) {
        return await loadingCounterWrapper(async () => {
            try {
                putStorage(`temp.auth_history_loading`, true)
                let history = (await axios.post(`${API_ENDPOINT}/user/auth-history`, POSTOptions(options))).data
                putStorage(`temp.auth_history_loading`, false)
                putStorage(`user.auth_history`, history)
                return history;
            } catch (error) {
                throw handleError(error)
            }
        })
    },

    async getNotifications(options) {
        return await loadingCounterWrapper(async () => {
            try {
                putStorage(`temp.notifications_loading`, true);
                let notifications = (await axios.get(`${API_ENDPOINT}/notifications`, GETOptions(options))).data
                putStorage(`temp.notifications_loading`, false)
                  putStorage(`user_page.notifications`, notifications)
                return notifications;
            } catch (error) {
                throw handleError(error)
            }
        })
    },

    async markNotificationsAsRead(payload) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.put(`${API_ENDPOINT}/notifications/read`, payload)).data
                await this.getNotifications();
                return response;
            } catch (error) {
                throw handleError(error)
            }
        })
    },

    async getLogs(options) {
        return await loadingCounterWrapper(async () => {
            try {
                putStorage(`temp.logs_loading`, true)
                let logs = (await axios.post(`${API_ENDPOINT}/user/log`, POSTOptions(options))).data
                putStorage(`temp.logs_loading`, false)
                putStorage(`user.logs`, logs)
                return logs;
            } catch (error) {
                throw handleError(error)
            }
        })
    },

    async getReferralInfo() {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.get(`${API_ENDPOINT}/referral-info`)).data
                putStorage(`referral`, response)
                return response
            } catch (error) {
                throw handleError(error)
            }
        })
    },

    async getNotificationsSettings() {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.get(`${API_ENDPOINT}/notification/setting`)).data
                putStorage(`notifications_settings`, response)
                return response
            } catch (error) {
                throw handleError(error)
            }
        })
    },

    async setNotificationsSettings(data) {
        console.log(data)
        return await loadingCounterWrapper(async () => {
            try {
                let reponse = (await axios.put(`${API_ENDPOINT}/notification/setting`, data)).data
                await this.getNotifications();
                return reponse
            } catch (error) {
                throw handleError(error)
            }
        })
    },

    async getAPIInfo() {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.get(API_ENDPOINT + `/user/api`)).data;
                putStorage(`user.api_info`, response)
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async updateAPIInfo(payload) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.put(API_ENDPOINT + `/user/api`, payload)).data;
                putStorage(`user.api_info`, response)
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async getTwoFactorAuth() {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.get(API_ENDPOINT + `/user/two-factor-auth`)).data;
                putStorage(`user.two_factor`, response)
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async toggleTwofactorAuth() {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.get(API_ENDPOINT + `/user/change-availability-two-factor-auth`)).data;
                putStorage(`user.two_factor`, response)
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async requestPhoneConfirmationCode() {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.get(API_ENDPOINT + `/phone/get-confirmation-code`)).data;
                handleSuccess(response);
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async getConfirmationCode() {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.get(API_ENDPOINT + `/email/get-confirmation-code`)).data;
                handleSuccess(response);
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async requestEmailConfirmation() {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.get(API_ENDPOINT + `/phone/get-confirmation-code`)).data;
                handleSuccess(response);
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async requestEmailCheck(email, code) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.get(API_ENDPOINT + `/email/confirm?email=${email}&code=${code}`)).data;
                handleSuccess(response);
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async checkPhoneConfirmationCode(code) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.get(API_ENDPOINT + `/phone/check-code/${code}`)).data;
                await this.getCurrentUser()
                handleSuccess(response);
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async getLastLoginInfo(code) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.get(API_ENDPOINT + `/user/last-login`)).data;
                putStorage(`temp.last_login_info`, response)
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async confirmRegistration(email, code) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.post(API_ENDPOINT + `/auth/confirm-email`, {email, code})).data;
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async getConfirmationCodeFromRegistration(email) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.post(API_ENDPOINT + `/auth/get-confirmation-code`, {email})).data;
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async getClicoIntegrationLink() {
      return await loadingCounterWrapper(async () => {
        try {
          return (await axios.get(`${API_ENDPOINT}/user/clico-integration`)).data;
        } catch(error) {
          throw handleError(error);
        }
      })
    },

    async getPaymentPendingRetentionTime() {
        return await loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${API_ENDPOINT}/user/payment-pending-days`)).data;
                //putStorage(`campaignsPaymentPending`, response);
                handleSuccess(response);
                return response;
            }catch(error) {
                throw handleError(error);
            }
        });
    },

    async setPaymentPendingRetentionTime(days /*number*/) {
        return await loadingCounterWrapper(async () => {
            try {
                const response = (await axios.put(`${API_ENDPOINT}/user/payment-pending-days`, {days})).data;
                //putStorage(`campaignsPaymentPending`, response);
                handleSuccess(response);
                return response;
            }catch(error) {
                throw handleError(error);
            }
        });
    }


}

export default UserAPI;
/*eslint-enable*/