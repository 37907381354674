/*eslint-disable*/
import React, { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'

let Calendar = (props) => {

    let { onChange = () => { }, selectedDate = +moment().add(-100, `years`), format = `YYYY-MM-DD` } = props;

    let [monthStart, setMonthStart] = useState(+moment().startOf(`month`))

    return (
        <Frame extra={`width: 230px; * { font-size: 12px; }`} >
            <Frame extra={`width: 100%; flex-direction: row; justify-content: space-between; margin-bottom: 10px;`} >
                <DoubleArrowBack onClick={() => { setMonthStart(+moment(monthStart).add(-1, `year`)) }} />
                <ArrowBack onClick={() => { setMonthStart(+moment(monthStart).add(-1, `month`)) }} />
                <MonthName onClick={() => { setMonthStart(+moment().startOf(`month`)) }} >
                    {moment(monthStart).format(`MMMM YYYY`)}
                </MonthName>
                <ArrowForward onClick={() => { setMonthStart(+moment(monthStart).add(1, `month`)) }} />
                <DoubleArrowForward onClick={() => { setMonthStart(+moment(monthStart).add(1, `year`)) }} />
            </Frame>
            <DaysOfWeekWrapper>
                {
                    moment()._locale._weekdaysShort.roll(1).map((item, index) => {
                        return (
                            <Frame key={index} >
                                {item}
                            </Frame>
                        )
                    })
                }
            </DaysOfWeekWrapper>
            <SeparationLine />
            <Frame extra={`width: 100%; flex-direction: row; flex-wrap: wrap; justify-content: space-between;`} >
                {
                    new Array(35).fill(0).map((item, index) => +moment(monthStart).startOf(`month`).startOf(`isoWeek`).add(index, `days`)).map((item, index) => {
                        return (
                            <Day
                                key={index}
                                thisMonth={monthStart === +moment(item).startOf(`month`)}
                                isToday={+moment().startOf(`day`) === item}
                                onClick={() => { onChange(moment(item).format(format)) }}
                                isSelectedDate={+moment(selectedDate).startOf(`day`) === item}
                            >
                                {moment(item).format(`DD`)}
                            </Day>
                        )
                    })
                }
            </Frame>
        </Frame>
    )
}

const Frame = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    transition: 0.2s;
    ${props => props.extra}
`;

const SeparationLine = styled(Frame)`
    width: 100%;
    opacity: 0.2;
    margin: 8px 0 24px 0;
    border: 1px dashed ${props => props.theme.text.secondary};
`;

const DoubleArrowBack = styled(Frame)`
    width: 24px;
    height: 24px;
    transform: rotate(0deg);
    background: url('${require(`../../assets/icons/calendar-arrow-back-double.svg`).default}') no-repeat center center / contain;
    cursor: pointer;
`;

const DoubleArrowForward = styled(Frame)`
    width: 24px;
    height: 24px;
    transform: rotate(180deg);
    background: url('${require(`../../assets/icons/calendar-arrow-back-double.svg`).default}') no-repeat center center / contain;
    cursor: pointer;
`;

const ArrowBack = styled(Frame)`
    width: 24px;
    height: 24px;
    transform: rotate(0deg);
    background: url('${require(`../../assets/icons/calendar-arrow-back.svg`).default}') no-repeat center center / contain;
    cursor: pointer;
`;

const ArrowForward = styled(Frame)`
    width: 24px;
    height: 24px;
    transform: rotate(180deg);
    background: url('${require(`../../assets/icons/calendar-arrow-back.svg`).default}') no-repeat center center / contain;
    cursor: pointer;
`;

const Day = styled(Frame)`
    width: calc(230px / 7);
    height: calc(230px / 7);
    opacity: ${props => props.thisMonth || props.isToday ? 1 : 0.5};
    border-radius: 50%;
    font-weight: ${props => props.isSelectedDate ? 500 : 400};
    color: ${props => props.isSelectedDate ? props.theme.blue : props.theme.text.secondary} !important;
    /* background: ${props => props.isToday ? props.theme.red : `transparet`}; */
    box-sizing: border-box;
    border: ${props => props.isSelectedDate ? 1 : 0}px solid ${props => props.theme.blue};
    /* &:hover {
        background: ${props => props.isSelectedDate ? props.theme.red : props.theme.background.support};
    } */
    cursor: pointer;
`;

const DaysOfWeekWrapper = styled(Frame)`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    > * {
        font-weight: normal;
        font-size: 10px;
        line-height: 16px;
        text-transform: uppercase;
        color: ${props => props.theme.text.secondary};
    };
`;

const MonthName = styled(Frame)`
    width: 150px;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;

    color: ${props => props.theme.text.dark};

`;

export default Calendar;
/*eslint-enable*/