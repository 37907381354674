/*eslint-disable*/
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { Frame, Button, Input, Dropdown, H1, P, Link } from '../ui-kit/styled-templates'
import countries from '../../constants/world-map-svg-paths.json'

let WorldMapChart = (props) => {

    let { width = 300, selected = [], extra = `` } = props

    let height = width / 1009.6727 * 665.96301

    return (
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 1000 665`} extra={extra} >
            { countries.map((item, index) => <Path key={index} {...item} selected={selected.includes(item.id)} />)}
        </Svg>
    )
}

const Path = styled.path`
    fill: ${props => props.selected ? props.theme.blue : props.theme.grey};
    transition: 0.2s;
`;

const Svg = styled.svg`
    width: 100%;
    /* height: 100%; */
    ${props => props.extra}
`;

export default WorldMapChart;
/*eslint-enable*/