/*eslint-disable*/
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

const Button = (props) => {

    const {
        onClick = () => { },
        extendedIndicators = false,
        confirm = false,
    } = props

    const [status, setStatus] = useState(`waiting`)

    const onClickPro = async () => {
        setStatus(`loading`)
        try {
            let response = await onClick()
            if (extendedIndicators) {
                setStatus(`success`)
                setTimeout(() => { setStatus(`waiting`) }, 1000)
            } else { setStatus(`waiting`) }
            return response
        } catch (error) {
            if (extendedIndicators) {
                setStatus(`error`)
                setTimeout(() => { setStatus(`waiting`) }, 1000)
            } else { setStatus(`waiting`) }
            throw error
        }
    }

    return (
        <Wrapper
            {...props}
            visible={props.visible === undefined ? true : props.visible}
            short={props.short === undefined ? true : props.short}
            status={status}
            disabled={props.disabled || status === `loading`}
            shaped={props.shaped || props.disabled || status === `loading`}
            onClick={async () => {
                if (!props.disabled) {
                    if (confirm) {
                        if (status === `confirm`) {
                            try {
                                await onClickPro()
                            } catch (error) {
                                return error
                            }
                        } else {
                            setStatus(`confirm`)
                        }
                    } else {
                        onClickPro()
                    }
                }
            }}
            bold
        >
            {status !== `waiting` ? status.uppercaseFirstLetter() : props.children ? props.children : `button`}
        </Wrapper>
    )
}

export default Button

const Wrapper = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 23px 30px 23px 30px;
    height: 16px;
    /* border-radius: 8px; */
    border-radius: 5px;
    color: ${props => !props.shaped ? `white` : props.disabled ? props.theme.text.secondary : props.background ? props.background : props.theme.blue} !important;
    min-width: 90px;
    cursor: ${props => props.disabled ? `default` : `pointer`};
    background: ${props => props.shaped ? `transparent` : props.status === `success` ? props.theme.green : props.status === `confirm` ? props.theme.yellow : props.status === `error` ? props.theme.red : props.disabled ? props.theme.background.support : props.background ? props.background : props.theme.orange} !important;
    border: 1px solid ${props => props.shaped ? props.status === `success` ? props.theme.green : props.status === `confirm` ? props.theme.yellow : props.status === `error` ? props.theme.red : props.disabled ? props.theme.text.secondary : props.background ? props.background : props.theme.blue : `transparent`};
    opacity: 1;
    text-align: center;
    line-height: 18px;
    transition: 0.2s;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: center;

    /* text-overflow: clip !important; */
    line-height: 0;

    &:hover {
        opacity: 0.8;
    }

    ${props => props.extra}

    @media (min-width: 320px) and (max-width: 480px) {
        padding: 23px 30px 23px 30px;
        border-radius: 2.5vw;
        font-size: 3.75vw;
        line-height: 0vw;
        border: 2px solid ${props => props.shaped ? props.status === `success` ? props.theme.green : props.status === `confirm` ? props.theme.yellow : props.status === `error` ? props.theme.red : props.disabled ? props.theme.background.support : props.background ? props.background : props.theme.blue : `transparent`};
    }
`
/*eslint-enable*/