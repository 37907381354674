import {SET_MODERATION_COUNTER} from "../constans";

const defaultState = {
  linkOnModerationAmount: 0,
  sumOfMessagesAmount: 0
};

const moderationCountersReducer = (state = defaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_MODERATION_COUNTER : return {
      ...state,
      linkOnModerationAmount: payload.linkOnModerationAmount,
      sumOfMessagesAmount: payload.sumOfMessagesAmount
    }; break;
    default: return state;
  }
}

export default moderationCountersReducer;