/*eslint-disable*/

let standalone = navigator.standalone || window.matchMedia('(display-mode: standalone)').matches

let additional = {
    shadow: {
        card: `box-shadow: 0px 30px 60px -28px rgba(7, 107, 156, 0.15);`,
    }
}

let basePalette = {

    blue: `#009BE8`,
    orange: `#FCA13A`,
    red: `#DC2425`,
    green: `#00C353`,
    grey: `#C5CEDD`,
    purple: `#A03FC2`,
    viber: `#9069AE`,

}

export let dayTheme = {
    ...basePalette,
    ...additional,
    background: {
        primary: `#FFFFFF`,
        secondary: `#F5F7FB`,
    },
    text: {
        primary: `#435179`,
        secondary: `#8798AD`,
        dark: `#2E384D`,
    }
}

export let darkTheme = {
    ...basePalette,
    ...additional,
    background: {
        // primary: standalone ? `#000000` : `#262626`,
        primary: window.innerWidth < 600 ? `#000000` : `#262626`,
        secondary: `#2B2B2B`,
        support: `#858585`,
    },
    text: {
        primary: `#FFFFFF`,
        // secondary: `#FF2F00`,
        secondary: `#7D7D7D`,
        support: `#939393`,
    }
}

export default {
    light: dayTheme,
    dark: darkTheme,
    theme_shift: {
        system: `system`,
        disabled: `scheduled`,
    },
}
/*eslint-enable*/