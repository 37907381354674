/*eslint-disable*/
import moment from 'moment-timezone'

import { eventDispatch } from '../hooks/useEventListener'
import { putStorage } from '../hooks/useStorage'
import { objectPut } from './common-helper'

export let handleSuccess = (response) => {
    if (!Array.isArray(response) && typeof (response.message) === `string`) {
        response = [response]
    }
    (response || [])?.forEach?.((i, index) => {
        setTimeout(() => {
            eventDispatch(`THROW_SUCCESS`, i?.message ?? ``)
        }, 200 * index)
    })
    return response || []
}

export let handleError = (error) => {
    if (!Array.isArray(error) && typeof (error?.message) === `string` && !Array.isArray(error?.response?.data)) {
        objectPut(error, `response.data`, [error?.response?.data])
    }
    if (error?.response?.data) {
        (error?.response?.data ?? []).forEach((i, index) => {
            setTimeout(() => {
                if (i?.field) {
                    eventDispatch(`CALL_INPUT_ERROR`, { field: i?.field ?? ``, message: i?.message ?? `` })
                    eventDispatch(`THROW_ERROR`, i?.message ?? (error?.message ?? ``))
                } else {
                    eventDispatch(`THROW_ERROR`, i?.message ?? (error?.message ?? ``))
                }
            }, 200 * index)
        })
        return error?.response?.data ?? []
    }
    if (error?.response?.data?.message) {
        eventDispatch(`THROW_ERROR`, error?.response?.data?.message)
        return error?.response?.data?.message
    }
    return error
}

export let loadingCounterWrapper = async (action) => {
    putStorage(`loading_counter`, (window?.storage?.loading_counter ?? 0) + 1)
    try {
        let response = await action();
        return response;
    } catch (error) {
        throw error
    } finally {
        putStorage(`loading_counter`, Math.max((window?.storage?.loading_counter ?? 0) - 1, 0))
    }
}

export let POSTOptions = (o = {}) => {
    return {
        sort: [
            ...(o?.sort ?? [])
        ],
        pagination: {
            page: 1,
            per_page: 15,
            ...(o?.pagination ?? {})
        },
        filter: Object.fromEntries(Object.entries({
            ...(o?.filter ?? {})
        }).filter(i => i[0] !== `group_by`)),
        group_by: [
            ...(o?.group_by ?? []),
            ...(o?.filter?.group_by ?? [])
        ]
    }
}

export let GETOptions = (options = {}) => {
    // Object.keys(options?.filter ?? {})
    //     .filter(i => [`_from`, `_to`]
    //     .map(j => i.includes(j))
    //     .includes(true))
    //     .forEach(i => {
    //       options.filter[i] = moment(options?.filter?.[i], `DD.MM.YYYY`).format(`YYYY-MM-DD HH:mm:ss`)
    // })
    return {
        params: {
            setting: JSON.stringify(POSTOptions(options))
        }
    }
}

/*eslint-enable*/