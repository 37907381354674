/*eslint-disable*/
import axios from "axios";

import { eventDispatch } from "../hooks/useEventListener";
import { mergeStorage, putStorage } from "../hooks/useStorage";
import { handleSuccess, handleError, loadingCounterWrapper, POSTOptions } from "../utils/api-helper";
import { isna } from "../utils/common-helper";
import { API_ENDPOINT } from "../constants/config";
import HLRAPI from "./hlr-api";

let cancelToken = null;

const StatsAPI = {
    async getDetailedStats(options) {
        return await loadingCounterWrapper(async () => {
            try {
                if(cancelToken) {
                    cancelToken.cancel();
                }
                cancelToken = axios.CancelToken.source();
                let response = (await axios.post(`${API_ENDPOINT}/stats/detailed`, POSTOptions(options), { cancelToken: cancelToken.token } )).data;
                putStorage(`stats.detailed`, response);
                return response;
            } catch (error) {
                throw handleError(error);
            }
        });
    },

    async getSummaryStats() {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.get(`${API_ENDPOINT}/stats/summary-country`)).data;
                putStorage(`stats.summary_country`, response);
                return response;
            } catch (error) {
                throw handleError(error);
            }
        });
    },

    async getSummarySubaccount() {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.get(`${API_ENDPOINT}/stats/summary-subaccount`)).data;
                putStorage(`stats.subaccount`, response);
                return response;
            } catch (error) {
                throw handleError(error);
            }
        });
    },

    async getSummaryCampaigns() {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.get(`${API_ENDPOINT}/stats/summary-campaigns`)).data;
                putStorage(`stats.campaigns`, response);
                return response;
            } catch (error) {
                throw handleError(error);
            }
        });
    },

    async getSummaryTrafficStats() {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.get(`${API_ENDPOINT}/stats/summary-traffic-type`)).data;
                putStorage(`stats.traffic`, response);
                return response;
            } catch (error) {
                throw handleError(error);
            }
        });
    },

    async getSummaryNetwork() {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.get(`${API_ENDPOINT}/stats/summary-network`)).data;
                putStorage(`stats.network`, response);
                return response;
            } catch (error) {
                throw handleError(error);
            }
        });
    },

    async getSummaryPeriod() {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.get(`${API_ENDPOINT}/stats/summary-period`)).data;
                putStorage(`stats.summary_report`, response);
                return response;
            } catch (error) {
                throw handleError(error);
            }
        });
    },

    async getSummary(options) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.post(`${API_ENDPOINT}/stats/summary`, POSTOptions(options))).data;
                putStorage(`stats.summary`, response);
                // statistics-summary
                // filters[`statistics-summary`].group_by
                // console.log(`> > >`, Object.keys(response?.items?.[0] ?? {}));
                return response;
            } catch (error) {
                throw handleError(error);
            }
        });
    },

    async getReport() {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.get(`${API_ENDPOINT}/stats/report`)).data;
                putStorage(`stats.report`, response);
                return response;
            } catch (error) {
                throw handleError(error);
            }
        });
    },

    async deleteReport(id) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.delete(`${API_ENDPOINT}/stats/report/${id}`)).data;
                await this.getReport();
                return response;
            } catch (error) {
                throw handleError(error);
            }
        });
    },

    async getAllStats() {
        return await loadingCounterWrapper(async () => {
            try {
                let o = {
                    ...(window?.storage?.stats ?? {}),
                    report: await this.getReport(),
                    summary_country: await this.getSummaryStats(),
                    subaccount: await this.getSummarySubaccount(),
                    campaigns: await this.getSummaryCampaigns(),
                    traffic: await this.getSummaryTrafficStats(),
                    network: await this.getSummaryNetwork(),
                    summary_report: await this.getSummaryPeriod(),
                    hlr: await HLRAPI.getHLRStats(),
                };
                // mergeStorage(`stats`, o)
                return;
            } catch (error) {
                throw handleError(error);
            }
        });
    },

    async getSummaryStatsGroupByItems() {
        return await loadingCounterWrapper(async () => {
            try {
                const response = (await axios.delete(`${API_ENDPOINT}/directory/summary-stats-group`)).data;
                return response;
            } catch (error) {
                throw handleError(error);
            }
        });
    },

    async getBasesIds() {
        return await loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${API_ENDPOINT}/directory/base`)).data;
                return response;
            } catch(error) {
                throw handleError(error);
            }
        })
    }
};

export default StatsAPI;

/*eslint-enable*/
