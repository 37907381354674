/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import moment from "moment-timezone";
import _ from "lodash";

import Calendar from "./calendar";

import { isna, sleep } from "../../utils/common-helper";

import useEventListener from "../../hooks/useEventListener";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import useDictionary from "../../hooks/useDictionary";
import useKeyHandler from "../../hooks/useKeyHandler";
import { convertHex } from "./styled-templates";
import {precache} from "workbox-precaching";

export default (props) => {
    const { useValueAsLabel = false } = props;
    let ref = useRef();
    let calendarRef = useRef();
    let [focus, setFocus] = useState(false);
    let [error, setError] = useState(false);
    let [calendarVisible, setCalendarVisible] = useState(false);
    useEffect(() => {
        let focusHandler = (event) => {
            if (!props.readOnly) {
                setFocus(true);
            }
        };
        let blurHandler = (event) => {
            setFocus(false);
        };
        ref.current.addEventListener(`focus`, focusHandler, true);
        ref.current.addEventListener(`blur`, blurHandler, true);
        return () => {
            if (ref.current) {
                ref.current.removeEventListener(`focus`, focusHandler, true);
                ref.current.removeEventListener(`blur`, blurHandler, true);
            }
        };
    }, []);

    const hasImage = props.image !== undefined;

    let { t } = useDictionary();

    useEventListener(`CALL_INPUT_ERROR`, async (e) => {
        if (
            [props?.name, props?.placeholder]
                .map((i) => i?.toCase(`snake`))
                .filter((i) => !isna(i))
                ?.includes(e?.detail?.field?.toCase(`snake`))
        ) {
            setError(true);
            await sleep(1000);
            setError(false);
        }
    });

    const [passwordVisible, setPasswordVisible] = useState(false);

    let inputType = props.type === `password` && passwordVisible ? `input` : props.type || `input`;

    let filteredOptions = props?.notFilter ? props.options : (props.options || []).filter((a, b) => {
        return (props.selectOnly || (props.value || ``) === `` || (props.options || [])
                    .map((i) => i.value).includes(props.value || ``))
            ? true
            : (a.label || ``).toLowerCase().includes(((props.value || ``) + ``).toLowerCase())
        }
    );

    const valueIsOnTheList = props?.select && filteredOptions?.map((i) => i?.value)?.includes(props?.value);


    if (!valueIsOnTheList) {
        filteredOptions = filteredOptions.sort(
            (a, b) =>
                b?.value?.toLowerCase?.()?.startsWith?.(props?.value?.toLowerCase?.()) -
                a?.value?.toLowerCase?.()?.startsWith?.(props?.value?.toLowerCase?.())
        );
    }

    useOnClickOutside(calendarRef, () => {
        setCalendarVisible(false);
    });

    useEffect(() => {
        // if ((props.select || props.type === `select`) && props.selectOnly) {
        //     if (!filteredOptions.map(i => i.label).includes(props.value) && !isna(props.value)) {
        //         setError(true)
        //     } else {
        //         setError(false)
        //     }
        // }
        if (props?.select && valueIsOnTheList) {
            const element = document?.getElementById(`scrolled-options-list-${props?.name ?? props?.placeholder}`);
            if (element) {
                element.scrollTop = 54 * filteredOptions?.map((i) => i?.value)?.indexOf(props?.value);
            }
        }
    }, [props.value]);

    useKeyHandler(
        `ArrowUp`,
        () => {
            if (props?.select && focus) {
                props.onChange({
                    target: {
                        value:
                            filteredOptions?.[
                                (filteredOptions?.map((i) => i?.value)?.indexOf(props?.value) - 1 + filteredOptions?.length) % filteredOptions?.length
                            ]?.value ?? ``,
                    },
                });
            }
        },
        { avoidInputArea: false }
    );

    useKeyHandler(
        `ArrowDown`,
        () => {
            if (props?.select && focus) {
                props.onChange({
                    target: {
                        value:
                            filteredOptions?.[
                                (filteredOptions?.map((i) => i?.value)?.indexOf(props?.value) + 1 + filteredOptions?.length) % filteredOptions?.length
                            ]?.value ?? ``,
                    },
                });
            }
        },
        { avoidInputArea: false }
    );

    const str = (_str) => {
        return (_str || []).map(v => v.value).join('')
    }

    return (
        <Wrapper extra={props.extra}>
            {props.type !== `range` && props.placeholder !== undefined ? (
                <Label
                    focus={focus}
                    error={error}
                    lifted={focus || [undefined, null, ``].indexOf(props.value) === -1}
                    hasImage={false}
                    // onFocusPlaceholder={props.onFocusPlaceholder || null}
                    onClick={() => {
                        ref.current.focus();
                    }}
                >
                    {focus && props.onFocusPlaceholder ? props.onFocusPlaceholder :  props.placeholder}
                </Label>
            ) : null}
            {props.image ? <Image image={props.image} visible={!(focus || (props.value || ``).length > 0)} /> : null}
            {props.type === `password` ? (
                <EyeOff
                    visible={passwordVisible}
                    onClick={() => {
                        setPasswordVisible(!passwordVisible);
                    }}
                />
            ) : null}
            {props.select ? (
                <SelectDropDownArrow
                    selected={!props.readOnly && focus}
                    onClick={() => {
                        ref.current.focus();
                    }}
                />
            ) : null}
            {props.calendar ? (
                <CalendarWrapper ref={calendarRef} visible={calendarVisible}>
                    <Calendar
                        format={props?.format ?? `YYYY-MM-DD`}
                        selectedDate={moment(props.value, props?.format ?? `YYYY-MM-DD`)}
                        onChange={(e) => {
                            props.onChange({ target: { value: e } });
                        }}
                    />
                </CalendarWrapper>
            ) : null}
            {props.clearImage ? (
                <ClearImage
                    onClick={() => {
                        if (props.onChange !== undefined) {
                            props.onChange({ target: { value: `` } });
                        }
                    }}
                    image={props.clearImage}
                    visible={focus || props.value.length > 0}
                />
            ) : null}
            {props.textarea ? (
                <Textarea ref={ref} onChange={() => {}} {...props} error={error} />
            ) : (
                <>
                    <Input
                        ref={ref}
                        onClick={() => {
                            if (props.calendar) {
                                setCalendarVisible(!calendarVisible);
                            }
                        }}
                        {...props}
                        value={
                            props.type === `select`
                                ? props.options?.find((i) => _.isEqual(i?.value, props.value))?.[useValueAsLabel ? `value` : `label`]
                                : props.value
                        }
                        onChange={
                            (props.type === `select` || props.select) && props.selectOnly
                                ? async (e) => {
                                      if (filteredOptions.length > 15) {
                                          props.onChange(e);
                                      } else {
                                          // if (e.nativeEvent.inputType === `deleteContentBackward`) {
                                          //     props.onChange({
                                          //         target: { value: `` }
                                          //     })
                                          // }
                                      }
                                  }
                                : props.onChange
                        }
                        disabled={props.disabled}
                        error={error}
                        focus={focus}
                        type={inputType}
                        readOnly={props.readonly}
                        placeholder={!isna(props.format) ? t(`@date_input_placeholder`) : null}
                    />
                    { props.isRequire ? (<Require> * {t('@require_value')} </Require>) : '' }
                    { props.childs }
                </>
            )}
            {props.select ? (
                <SelectList
                    visible={!props.readOnly && focus}
                    id={`scrolled-options-list-${props?.name ?? props?.placeholder}`}>
                    {filteredOptions.map((item, index) => {
                        return (
                            <Option
                                key={index}
                                selected={_.isEqual(item?.value, props?.value)}
                                {...item}
                                onClick={() => {
                                    if (props.onChange !== undefined && !item.disabled) {
                                        props.onChange({ target: { value: item.value } });
                                    }
                                }}
                            >
                                {item.label}
                            </Option>
                        );
                    })}
                    {filteredOptions.length === 0 ? <P>{t('@no_options')}</P> : null}
                </SelectList>
            ) : null}
            {props.errorMessage ? <ErrorMessage>{ props.errorMessage }</ErrorMessage>: ''}
        </Wrapper>
    );
};

const Require = styled.span`
  font-size: 0.6em;
  color: #444;
  position: absolute;
  right: 0;
  top: -1.3em;
`;

const ErrorMessage = styled.span`
  font-size: 0.6em;
  color: red;
`;

const CalendarWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    background: ${(props) => props.theme.background.primary};
    box-shadow: 0px 10px 20px rgba(0, 155, 232, 0.1);
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    transition: 0.2s;
    top: 100%;
    right: 0;
    transform: translateY(${(props) => (props.visible ? 0 : -10)}px);

    visibility: ${(props) => (props.visible ? `visible` : `hidden`)};
    opacity: ${(props) => (props.visible ? 1 : 0)};
`;

const Option = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    /* line-height: 24px; */

    display: flex;
    align-items: center;

    color: ${(props) => props.theme.text.dark};

    cursor: pointer;
    transition: 0.2s;

    background: ${(props) => convertHex(props.theme.blue, props.selected ? 0.05 : 0)};
    padding: 12px;
    box-sizing: border-box;
    border-radius: 8px;

    &:hover {
        transform: translateX(2px);
    }
    cursor: ${(props) => props.disabled ? 'not-allowed' : 'auto'};
    opacity: ${(props) => props.disabled ? '0.8' : '1'};
`;

const P = styled(Option)`
    font-weight: 400;

    color: ${(props) => props.theme.text.secondary};

    @media (min-width: 320px) and (max-width: 480px) {
        font-size: 3.75vw;
        line-height: 6vw;
    }
`;

const SelectList = styled.div`
    display: block;
    width: 100%;
    //min-width: max-content;
    max-height: 256px;
    overflow-y: scroll;
    padding: 16px;
    box-sizing: border-box;
    background: ${(props) => props.theme.background.primary};
    box-shadow: 0px 0px 5px rgba(0, 107, 168, 0.05), 0px 25px 35px rgba(0, 107, 168, 0.13);
    border-radius: 4px;
    position: absolute;
    z-index: 2;
    /* top: 100%; */
    right: 0;
    transform: translateY(${(props) => (props.visible ? 6 : 0)}px);

    visibility: ${(props) => (props.visible ? `visible` : `hidden`)};
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transition: 0.2s;

    > * {
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const SelectDropDownArrow = styled.div`
    width: 24px;
    height: 24px;
    background: url("${(props) => require(`../../assets/icons/select-dropdown-arrow.svg`).default}") no-repeat center center / contain;
    position: absolute;
    top: 50%;
    transition: 0.2s;
    transform: translateY(-50%) rotate(${(props) => (props.selected ? 180 : 0)}deg);
    right: 14px;
    cursor: pointer;

    &:hover {
        opacity: 0.75;
    }
`;

const EyeOff = styled.div`
    width: 24px;
    height: 24px;
    background: url("${(props) => require(`../../assets/icons/eye-${props.visible ? `on` : `off`}.svg`).default}") no-repeat center center / contain;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 14px;
    cursor: pointer;

    &:hover {
        opacity: 0.75;
    }
`;

const Wrapper = styled.div`
    ${(props) => props.extra}
    background: transparent !important;
    position: relative !important;
`;

const Label = styled.div`
    display: flex;
    position: absolute;
    box-sizing: border-box;
    top: ${(props) => (props.lifted ? -10 : 15)}px;
    left: ${(props) => +(props.hasImage && !props.lifted) * 16 + 12}px;
    font-size: ${(props) => (props.lifted ? 12 : 14)}px;
    transition: 0.1s;
    background: ${(props) => (props.lifted ? props.theme.background.primary : `transparent`)};
    padding: 2px 8px;
    border-radius: 4px;
    color: ${(props) => (props.error ? props.theme.red : props.focus ? props.theme.blue : props.theme.grey)} !important;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    cursor: text;

    @media only screen and (max-width: 600px) {
        z-index: 0;
    }
`;

const Image = styled.div`
    display: flex;
    position: absolute;
    width: 24px;
    height: 24px;
    right: 10px;
    top: 50%;
    transform: scale(${(props) => +props.visible}) translateY(-50%);
    opacity: ${(props) => +props.visible};
    transition: 0.2s;
    background: url(${(props) => props.image}) no-repeat center center / contain;
`;

const ClearImage = styled(Image)`
    right: 17px;
    left: auto;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
        opacity: 0.75;
    }
`;

const Textarea = styled.textarea`
    -webkit-appearance: none;
    outline: none;
    width: ${(props) => (props.short ? 255 : 540) - 34}px;
    resize: none;
    min-height: calc(112px - 34px);
    font-size: 14px;
    padding: 17px;
    background: ${(props) => props.theme.background.primary};
    border: 1px solid ${(props) => (props.error ? props.theme.red : props.accept ? props.theme.green : props.theme.grey)};
    border-radius: 5px;
    color: ${(props) => props.theme.text.primary};
    transition: 0.2s;
    ::placeholder {
        color: transparent;
    }
    &::-webkit-input-placeholder {
        ${(props) => (props.placeholderColor === undefined ? null : `color: ${(props) => props.placeholderColor}`)}
    }

    &:focus {
        ${(props) =>
            !props.readOnly
                ? css`
                      border: 1px solid ${(props) => (props.error ? props.theme.red : props.theme.blue)};
                  `
                : null}
    }

    @media (min-width: 320px) and (max-width: 480px) {
        width: calc(90vw - 34px);
        height: calc(31.25vw - 2 * 3.75vw);
        padding: 3.75vw 4.5vw;
        display: flex;
    }

    @supports (-webkit-touch-callout: none) {
    }

    ${(props) => {
        // console.log(props.extra)
        return props.extra;
    }}

    width: calc(100% - 16px - 20px) !important;
    height: ${(props) => (props.large ? `300px` : `calc(100% - 16px - 20px)`)};
    margin: 0;
`;

const Input = styled.input`
    -webkit-appearance: none;
    outline: none;
    width: ${(props) => (props.short ? 255 : 540) - 34}px;
    height: ${48 - 34}px;
    font-size: 14px;
    padding: 17px;
    transition: 0.2s;

    width: calc(300px - 34px - 30px);
    border-radius: 4px;
    background: ${(props) => (props.theme.text.primary === `#FFFFFF` ? `transparent` : props.theme.background.support)};
    border: 1px solid ${(props) => (props.error ? props.theme.red : props.accept ? props.theme.green : props.theme.grey)};
    color: ${(props) => (props.readOnly ? props.theme.grey : props.theme.text.dark)} !important;

    font-style: normal;
    font-weight: normal;
    line-height: 24px;

    ${(props) => (props.readOnly ? `cursor: default;` : ``)}
    ${(props) => (props.selectOnly ? `caret-color: transparent;` : ``)}

    &:focus {
        ${(props) =>
            !props.readOnly
                ? css`
                      border: 1px solid ${(props) => (props.error ? props.theme.red : props.theme.blue)};
                  `
                : null}
    }
    ::-webkit-slider-thumb {
        /* border: 0.1vw solid #0ff050; */
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: white;
        cursor: pointer;
        -webkit-appearance: none;
    }
    ::placeholder {
        font-size: 14px;
        color: ${(props) => (!isna(props.format) && props.focus ? props.theme.text.secondary : `transparent`)};
    }
    @media (min-width: 320px) and (max-width: 480px) {
        width: calc(90vw - 34px);
        height: ${(props) => +props.visible * 5}vw;
        padding: ${(props) => +props.visible * 5}vw;
        border-radius: 1.25vw;
        margin: ${(props) => +props.visible}vw;
        font-size: 4vw;
        padding: 5vw;
    }
    &::-webkit-input-placeholder {
        ${(props) => (props.placeholderColor === undefined ? null : `color: ${(props) => props.placeholderColor}`)}
    }
    &::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      pointer-events: none;
      position: absolute;
      right: 0;
    }

    ${(props) => props.extra}

    width: calc(100% - 16px - 20px) !important;
    margin: 0;
`;
/*eslint-enable*/
