/*eslint-disable*/
import axios from "axios";
import { API_ENDPOINT } from "../constants/config";
import yaml from "js-yaml";

import { putStorage } from "../hooks/useStorage";
import { handleSuccess, handleError, loadingCounterWrapper } from "../utils/api-helper";

import { eventDispatch } from "../hooks/useEventListener";
import {LOCALES} from "../utils/locales";

const CommonAPI = {
    async getMatching() {
        try {
            const response = (await axios.get(`${API_ENDPOINT}/directory/matching`)).data;
            putStorage(`temp.matching`, response);
            return response;
        } catch (error) {
            throw handleError(error);
        }
    },

    async getMainInfo() {
        try {
            const lang = window.localStorage.getItem('lang');
            const currentLang = (lang === 'null' || !lang) ? LOCALES.Default : lang;
            const response = (await axios.get(`${API_ENDPOINT}/main`, {
                headers: {
                    "Accept-Language": currentLang
                }
            })).data;
            putStorage(`temp.main`, response);
            return response;
        } catch (error) {
            throw handleError(error);
        }
    },

    async getAPIInfo() {
        return loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${API_ENDPOINT}/directory/api-info` )).data;
                const swagger = yaml.load(response.data_swagger_yaml);
                putStorage(`temp.api`, response);
                putStorage(`temp.api.swagger`, swagger);
                putStorage(
                    `temp.api.swagger.endpoints`,
                    Object.entries(swagger?.paths ?? {})
                        .map(([path, methods]) =>
                            Object.entries(methods).map(([method, data]) => ({
                                path,
                                method,
                                ...data,
                                link: `/api/open-api/${data?.operationId}`,
                                link_api: response?.link_api,
                                name: data?.summary?.uppercaseFirstLetter?.(),
                            }))
                        )
                        .flat()
                );
                return response;
            } catch (error) {
                throw handleError(error);
            }
        });
    },

    async getCurrencyList() {
        return loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${API_ENDPOINT}/directory/currency`)).data;
                putStorage(`temp.currency`, response);
                return response;
            } catch (error) {
                throw handleError(error);
            }
        });
    },

    async getAvailablePaymentSystems() {
        return loadingCounterWrapper(async () => {
            try {
                const response = (await axios.get(`${API_ENDPOINT}/directory/active-payment-systems`)).data;
                putStorage(`available_payment_system`, response);
                return response;
            } catch (error) {
                throw handleError(error);
            }
        });
    },
};

export default CommonAPI;
/*eslint-enable*/
