/*eslint-disable*/
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getStorage, putStorage } from "./useStorage";

const useChronicler = () => {
    const location = useLocation();
    useEffect(() => {
        putStorage(`history`, [...getStorage(state => state?.history ?? []), location])
    }, [JSON.stringify(location)])
    return null
};

export default useChronicler;
/*eslint-enable*/