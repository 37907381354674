/*eslint-disable*/
import axios from 'axios'
import moment from 'moment-timezone'
import SubscribersAPI from "../api/subscribers-api";

const CommonHelper = {

    getObjectType(o) {
      let regex = /^\[object (\S+?)\]$/;
      let matches = Object.prototype.toString.call(o).match(regex) || [];
      return (matches[1] || 'undefined').toLowerCase();
    },

    throttle(func, ms) {
        let isThrottled = false,
            savedArgs,
            savedThis;

        function wrapper() {

            if (isThrottled) {
                savedArgs = arguments;
                savedThis = this;
                return;
            }

            func.apply(this, arguments);

            isThrottled = true;

            setTimeout(function() {
                isThrottled = false;
                if (savedArgs) {
                    wrapper.apply(savedThis, savedArgs);
                    savedArgs = savedThis = null;
                }
            }, ms);
        }

        return wrapper;
    },

    linkTo(patientUrl, withScroll = true, useHash = true) {
        let url = window.location.origin + window.location.pathname + '#' + patientUrl;
        //let url = window.location.origin + window.location.pathname + `${useHash ? '#' : ''}` + patientUrl;
        window.location.href = url;
        if (withScroll) {
            try { document.getElementById(`scrollWrapper`).scrollTo({ top: 0, behavior: "smooth", }); } catch (error) { }
            try { window.scrollTo({ top: 0, behavior: "smooth", }); } catch (error) { }
        }
    },

    getContainerDimensions(containerSize = {}, proportion = 1.0) {
        let { width, height } = containerSize;
        let containerRatio = (width === undefined) ? 1 : (1.0 * width / height);
        let videoWidth = width, videoHeight = height;
        if (proportion > containerRatio) { // wider
            videoWidth = width;
            videoHeight = 1.0 * width / proportion
        } else { // narrower
            videoHeight = height;
            videoWidth = 1.0 * height * proportion;
        }
        return {
            width: videoWidth,
            height: videoHeight
        }
    },

    getCurrentUserRole(state) {
        if (state === undefined) {
            return false;
        }
        let { currentUserId, usersMap } = state.users;
        let currentUser = usersMap.get(currentUserId);
        if (currentUser === undefined) {
            return undefined;
        }
        return currentUser.userRole;
    },

    getUserName(user) {
        if (user === undefined) {
            return 'Guest';
        }
        let { firstName, lastName, name } = user.info;
        if (firstName === undefined && lastName === undefined) {
            return `Carol Black`;
        }
        return `${firstName} ${lastName}`;
    },

    async downloadSomething(url) {
        try {
            const response = await (axios.get(url, { headers: { "Access-Control-Allow-Origin": `origin-list` } }));
            console.log(response);
            return response
        } catch (error) {
            console.error(error)
        }
    },

    downloadFile(filename, text) {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    },

    async getFile(url, type = 'audio/mp3') {
        // console.log('getFile: url, type = ', url, type);
        let d = (await axios.get(url, {
            responseType: 'arraybuffer'
        })).data;
        const blob = new Blob([d], {
            type: type,
        });
        return blob;
    },

    async getCachedFileBlobUrl(url, type = 'image/jpg') {
        let registry = window.cachedRegistry || {};
        if (registry[url] !== undefined) {
            return registry[url];
        }
        let d = await this.getFile(url, type);
        let objectUrl = window.URL.createObjectURL(d);
        registry[url] = objectUrl;
        window.cachedRegistry = registry;
        return objectUrl;
    },

    async getCachedAudioUrl(url) {
        let registry = window.cachedRegistry || {};
        if (registry[url] !== undefined) {
            return registry[url];
        }
        let d = await this.getFile(url, 'audio/mp3');
        let objectUrl = window.URL.createObjectURL(d);
        registry[url] = objectUrl;
        window.cachedRegistry = registry;
        return objectUrl;
    },

    getGradColor(ratio = 0.5) {
        let color1 = '27E634';
        let color2 = 'F73434';
        let hex = function (x) {
            x = x.toString(16);
            return (x.length === 1) ? '0' + x : x;
        };
        let r = Math.ceil(parseInt(color1.substring(0, 2), 16) * ratio + parseInt(color2.substring(0, 2), 16) * (1 - ratio));
        let g = Math.ceil(parseInt(color1.substring(2, 4), 16) * ratio + parseInt(color2.substring(2, 4), 16) * (1 - ratio));
        let b = Math.ceil(parseInt(color1.substring(4, 6), 16) * ratio + parseInt(color2.substring(4, 6), 16) * (1 - ratio));
        return `#${hex(r)}${hex(g)}${hex(b)}`
    },

    async copyToClipboard(text) {
        if (window.clipboardData && window.clipboardData.setData) {
            return window.clipboardData.setData("Text", text);

        }
        else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed";
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand("copy");
            }
            catch (ex) {
                console.warn("Copy to clipboard failed.", ex);
                return false;
            }
            finally {
                document.body.removeChild(textarea);
            }
        }
    },

    objectStringToPath: (path) => {
        return path.replaceAll(`[`, `.`).replaceAll(/[\?\]\"\'\`]/g, ``).replaceAll(`..`, `.`).split(`.`).filter(i => i.length)
    },

    objectPut: (obj, path, val) => {
        // Source: https://vanillajstoolkit.com/helpers/put/
        path = CommonHelper.objectStringToPath(path);
        //console.log('path', path);
        let length = path.length;
        let current = obj;
        path.forEach((key, index) => {
            let isArray = key.slice(-2) === '[]';
            key = isArray ? key.slice(0, -2) : key;
            if (isArray && !Array.isArray(current[key])) {
              current[key] = [];
            }
            if (index === length - 1) {
              //console.log('1', key, current)
              if (isArray) {
                //console.log('1.1', key, current)
                current[key].push(val);
              } else {
                //console.log('1.2', key, current)
                current[key] = val;
              }
              //console.log('1.3', current[key])
            } else {
              //console.log('2', current[key])
              if (!current[key]) {
                //console.log('2.1', key, current)
                current[key] = {};
                //console.log('2.3', current[key])
              }
              //console.log('2.2', key,  current)
              current = current[key];
            }
        });
    },

    objectMerge: (obj, path, val) => {
        // Source: https://vanillajstoolkit.com/helpers/put/
        path = CommonHelper.objectStringToPath(path);
        let length = path.length;
        let current = obj;
        path.forEach((key, index) => {
            let isArray = key.slice(-2) === '[]';
            key = isArray ? key.slice(0, -2) : key;
            if (isArray && !Array.isArray(current[key])) { current[key] = []; }
            if (index === length - 1) { if (isArray) { current[key] = [...current[key], ...val]; } else { current[key] = { ...current[key], ...val }; } }
            else { if (!current[key]) { current[key] = {}; } current = current[key]; }
        });
    },

    objectGet: (obj, path) => {
        CommonHelper.objectStringToPath(path).forEach(key => { obj = obj?.[key] });
        return obj
    },

    objectCopy: (obj) => JSON.parse(JSON.stringify(obj) ?? null),

    // refactor late check time format from server (patchWizardForm)
    reportToForm: (report, withId = true) => {
        let bases = window?.storage?.subscribers ?? []
        let o = {
            // ...report,
            // ...report.message,
            // ...report.recipients,
            // ...report.settings,
            validity_hour: (`00` + (+report?.settings?.validity_period?.split(`:`)[0] || 0))?.slice(-2),
            validity_min: (`00` + (+report?.settings?.validity_period?.split(`:`)[1] || 0))?.slice(-2),
            message_type: report?.message?.type,
            text: report?.message?.text,
            short_link: false,
            title: report?.title,
            sender_name: report?.settings?.sender,
            delay: +moment(report?.settings?.sending_time, `DD.MM.YYYY HH:mm`) > +moment() ? `later` : `now`,
            sending_time: +moment(report?.settings?.sending_time, `DD.MM.YYYY HH:mm`),
            sending_hour: moment(report?.settings?.sending_time, `DD.MM.YYYY HH:mm`).format(`HH`),
            sending_min: moment(report?.settings?.sending_time, `DD.MM.YYYY HH:mm`).format(`mm`),
            additinal_numbers: report?.recipients?.phones,
            timezone: report?.settings?.timezone,
            bases: (report?.recipients?.bases?.split(`, `) ?? [])?.map(i => bases?.filter(j => j.name === i)?.[0]?.id).filter(i => !isna(i)),
        }
        if (withId) {
            o.id = report.id
        }
        return (o)
    },

    getBasesIdBySubscribers: (basesString, subscribers) => {
        let namesList = (basesString || '').split(',').map(name => {
            return name.trim();
        });
        let idsList = subscribers.filter(subscriber => {
            return namesList.indexOf(subscriber.name) !== -1;
        }).map(sub => {
            return sub.id;
        });
        return idsList;
    },

    patchWizardForm: (data, useInternalId = true) => {

        let bases = window?.storage?.subscribers ?? []
        let o = {
            // ...report,
            // ...report.message,
            // ...report.recipients,
            // ...report.settings,
            validity_hour: (`00` + (+data?.settings?.validity_period?.split(`:`)[0] || 0))?.slice(-2),
            validity_min: (`00` + (+data?.settings?.validity_period?.split(`:`)[1] || 0))?.slice(-2),
            message_type: data?.message?.type,
            text: data?.message?.text,
            short_link: false,
            title: data?.title,
            sender_name: data?.settings?.sender,
            delay: +moment(data?.settings?.sending_time) > +moment() ? `later` : `now`,
            sending_time: +moment(data?.settings?.sending_time),
            sending_hour: moment(data?.settings?.sending_time).format(`HH`),
            sending_min: moment(data?.settings?.sending_time).format(`mm`),
            additinal_numbers: data?.recipients?.phones,
            timezone: data?.settings?.timezone,
            bases: CommonHelper.getBasesIdBySubscribers(data?.recipients?.bases, bases)
        }
        if(useInternalId) {
            o.id = data.id;
        }
        return o;
    },

    mask: (value = ``, pattern = ``, placeholderSymbol = ``) => {
        let result = [];
        let shift = 0;
        pattern.split(``).forEach((i, j) => {
            if (i === `#`) {
                result.push(value?.[j - shift] ?? placeholderSymbol)
            } else {
                if (!value?.[j - shift]) {
                    console.log(false);
                    return result.join(``)
                }
                shift += 1;
                result.push(i)
            }
        })
        console.log(true);
        return result.join(``);
    },

    unmask: (value = ``, pattern = ``, placeholderSymbol = ``) => pattern.split(``).map((i, j) => i === `#` ? value?.[j] ?? placeholderSymbol : ``).join(``),

    formatToMask: (format) => format.replaceAll(/[A-Za-z]/g, `#`),

    boundMomentDate: (momentDate) => {
        return moment(moment(momentDate)._a.map((i, j) => ((value, bounds) => Math.min(Math.max(value, bounds?.[0] ?? Infinity), bounds?.[1] ?? -Infinity))(i, [
            [1900, Infinity], // years
            [0, 11], // months
            [1, 31], // days
            [0, 23], // hours
            [0, 59], // minutes
            [0, 59], // seconds
            [0, 999], // milliseconds
        ][j])))
    },

    queryParser: (search) =>
        Object.fromEntries(
            search
                .replace(/^\?/, '')
                .split('&')
                .map((i) => i.split(`=`))
                .map((i) => [i[0], decodeURI(i[1])]),
        ),
    setCampaignPageOnStorage(name) {
        window.localStorage.setItem('__prev_before_wizard_page_id__', name);
    },
    getCampaignPageOnStorage() {
        return window.localStorage.getItem('__prev_before_wizard_page_id__') || 'sent';
    },
    setDataByNestingKey({keysChain/*@string*/, value, obj, createKey/*boolean*/}) {
        const list = keysChain.split('.');
        const last = list[list.length - 1];
        let co = obj;
        list.forEach((key, index) => {
            if((last === key) && (index === list.length - 1)) {
                co[key] = value;
            }else{
                try {
                    co = co[key];
                }catch(err) {
                    console.log(err)
                }
            }
        });
        return obj;
    }
};


export default CommonHelper;

export const { queryParser, boundMomentDate, mask, unmask, formatToMask, linkTo, reportToForm, patchWizardForm, copyToClipboard, objectPut, objectMerge, objectGet, objectCopy, downloadFile, downloadSomething, getFile, } = CommonHelper;

export let isna = (item) => [undefined, null, ``].includes(item);

export let path = (s) => {
    if (Array.isArray(s)) {
        s = s[0];
    }
    if (typeof (s) !== `string`) {
        throw new Error(`path should be a string`)
    }
    let [stringPath, exeption] = s.split(` ?? `);
    return ref => CommonHelper.objectGet(ref, CommonHelper.objectStringToPath(stringPath).slice(1).join(`.`)) ?? exeption
};

export let getType = (obj) => ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase();

export let createId = () => Math.random().toString(36).substring(3).split(``).map(i => i[Math.random() > 0.5 ? `toLowerCase` : `toUpperCase`]()).join(``)

export let sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export let validateOnlyDigits = (s) => /^\d+$/.test(s || `0`);

/*eslint-enable*/