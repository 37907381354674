import {combineReducers, createStore} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import loaderReducer from "./reducers/loader";
import supportCountersReducer from "./SupportModule/reducers/counter";
import moderationCountersReducer from "./CampaignModule/reducers/moderations";

const rootReducer = combineReducers({
    loader: loaderReducer,
    supportCountersReducer,
    moderationCountersReducer
});

export const store = createStore(rootReducer, composeWithDevTools());

