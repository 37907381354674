import {SET_SUPPORT_COUNTER_AMOUNT} from "../constans";

const defaultState = {
    amount: 0
};

const supportCountersReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_SUPPORT_COUNTER_AMOUNT: return {...state, amount: action.payload }; break;
        default: return state;
    }
}

export default supportCountersReducer;