/*eslint-disable*/
import React, {useEffect, useRef} from 'react';
import './App.css';
import RouterApp from "./components/apps/router-app";
import { ThemeWrapper } from './components/ui-kit/styled-templates'
import { HashRouter, BrowserRouter } from 'react-router-dom';
import useTranslate from "./hooks/useTranslate";
import {putStorage} from "./hooks/useStorage";
import axios from "axios";


import CommonHelper from "./utils/common-helper";
import {VersionApi} from "./api/version-api";

let App = (props) => {
    useEffect(() => { window.storage = {}; }, []);

    const { translate, locale, loadTranslateDict } = useTranslate();

    useEffect(() => {
        putStorage(`i18nDict`, translate);
        axios.interceptors.request.use((config) => {
            if(!!locale) {
                config.headers.common["Accept-Language"] = locale;
            }
            return config;
        });
    }, [translate]);

    VersionApi.getBackendVersion();

    CommonHelper.setCampaignPageOnStorage('');

    return (
      <>
          {
              loadTranslateDict ? <ThemeWrapper>
                  <HashRouter>
                      <RouterApp />
                  </HashRouter>
              </ThemeWrapper> : null
          }
      </>

    )
}
export default App;

/*eslint-enable*/