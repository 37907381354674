/*eslint-disable*/
import axios from 'axios'
import { API_ENDPOINT } from "../constants/config";
import { eventDispatch } from '../hooks/useEventListener';

import { mergeStorage, putStorage } from '../hooks/useStorage'
import { handleSuccess, handleError, loadingCounterWrapper, GETOptions, POSTOptions } from '../utils/api-helper'
import { isna, sleep } from '../utils/common-helper';

const SubscribersAPI = {

    async getSubscribersList(options) {
        return await loadingCounterWrapper(async () => {
            try {
                let subscribers = (await axios.get(`${API_ENDPOINT}/subscribers`, GETOptions(options))).data
                putStorage(`subscribers`, subscribers.map(i => (window?.storage?.subscribers ?? []).map(i => i.id).includes(i.id) ? { ...window.storage.subscribers.get(i.id), ...i } : i))
                return subscribers
            } catch (error) {
                throw handleError(error)
            }
        })
    },

    async createSubscribersList(data) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.post(`${API_ENDPOINT}/subscribers/create`, data)).data;
                await this.getSubscribersList();
                return response
            } catch (error) {
                throw handleError(error)
            }
        })
    },

    async updateSubscribersList(data) {
        return await loadingCounterWrapper(async () => {
            try {
                let accounts = (await axios.put(`${API_ENDPOINT}/subscribers/${data.id}`, data)).data;
                this.getSubscribersList();
                return accounts
            } catch (error) {
                throw handleError(error)
            }
        })
    },

    async deleteSubscribersList(id) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.delete(`${API_ENDPOINT}/subscribers/${id}`)).data
                handleSuccess(response)
                await this.getSubscribersList()
                return response
            } catch (error) {
                throw handleError(error)
            }
        })
    },

    async getConatsFromList(id, options = {}) {
        return await loadingCounterWrapper(async () => {
            try {
                let contacts = (await axios.post(`${API_ENDPOINT}/subscribers/list/${id}`, POSTOptions(options))).data;
                putStorage(`subscribers[${(window?.storage?.subscribers ?? []).map(i => +i.id).indexOf(+id)}].contacts`, contacts)
                return contacts
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async duplicateBase(from, to) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.post(`${API_ENDPOINT}/subscribers/duplicate`, { source_base_id: from, destination_base_id: to })).data;
                handleSuccess(response)
                await this.getSubscribersList();
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async addContactToBase(data) {
        return await loadingCounterWrapper(async () => {

            let o = {};
            [`id`, `phone`, `email`, `first_name`, `middle_name`, `last_name`, `birth_day`, `gender`].forEach((item, index) => {
                if (!isna(data[item])) {
                    o[item] = data[item];
                }
            });
            try {
                let response = (await axios.post(`${API_ENDPOINT}/subscribers/add-contact/${data.listId}`, o)).data;
                await this.getSubscribersList();
                await this.getConatsFromList(data.listId);
                handleSuccess(response);
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async updateContact() {
        return await loadingCounterWrapper(async () => {
            try {
                // let response = (await axios.put(`${API_ENDPOINT}/subscribers/update-contact/${data[`id`]}`)).data;
                handleSuccess(response);
                // Some code here
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async removeContactFromBase(listId, idsArray) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.post(`${API_ENDPOINT}/subscribers/remove-contact/${listId}`, idsArray)).data;
                await this.getSubscribersList();
                await this.getConatsFromList(listId);
                handleSuccess(response);
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async getBlackListedContacts(options) {
        return await loadingCounterWrapper(async () => {
            try {
                let contacts = (await axios.get(`${API_ENDPOINT}/subscribers/blacklist`, GETOptions(options))).data;
                putStorage(`barred-list`, contacts);
                return contacts;
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    // async unblockContacts(options) {
    //     return await loadingCounterWrapper(async () => {
    //         try {
    //             let contacts = (await axios.get(`${API_ENDPOINT}/subscribers/blacklist`, GETOptions(options))).data;
    //             putStorage(`barred-list`, contacts);
    //             return contacts;
    //         } catch (error) {
    //             throw handleError(error);
    //         }
    //     })
    // },

    async blockContact(listId, description, contact_ids) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.post(`${API_ENDPOINT}/subscribers/block-contacts/${listId}`, { description, contact_ids })).data;
                await this.getSubscribersList();
                await this.getConatsFromList(listId);
                await this.getBlackListedContacts();
                handleSuccess(response);
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async deleteFormBlackList(contact_ids) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.post(`${API_ENDPOINT}/subscribers/delete-from-blacklist`, contact_ids)).data;
                await this.getSubscribersList();
                await this.getBlackListedContacts();
                handleSuccess(response);
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async unblockContactsByIds(baseId, contact_ids) {
      return await loadingCounterWrapper(async () => {
          try {
              let response = (await axios.post(`${API_ENDPOINT}/subscribers/unblock-contacts/${baseId}`, {contact_ids})).data;
              handleSuccess(response);
              return response;
          } catch (error) {
              throw handleError(error);
          }
      })
    },



    async copyContactToBase(source_base_id, destination_base_id, contact_ids) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.post(`${API_ENDPOINT}/subscribers/copy-contacts/${source_base_id}`, { destination_base_id, contact_ids })).data;
                await this.getSubscribersList();
                await this.getConatsFromList(source_base_id);
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async moveContactToBase(source_base_id, destination_base_id, contact_ids) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.post(`${API_ENDPOINT}/subscribers/move-contacts/${source_base_id}`, { destination_base_id, contact_ids })).data;
                await this.getSubscribersList();
                await this.getConatsFromList(source_base_id);
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async fillBase(data) {
        return await loadingCounterWrapper(async () => {
            try {
                let { data: response, status } = (await axios.post(`${API_ENDPOINT}/subscribers/fill-base/${data.base_id}`, data));

                if (status === 200) {
                    handleSuccess(response)
                    await this.getSubscribersList();
                    await this.getConatsFromList(data.base_id);
                    mergeStorage(`forms.create_list.fill_progress`, {
                        progress: 1,
                        processed: 0,
                        added: (data?.table?.rows?.length ?? 0) + ((data?.manually ?? ``)?.split(`,`) ?? [])?.length ?? 0,
                        updated: 0,
                        errors: 0,
                    })
                }
                if (status === 201) {
                    if (response?.id) {
                        putStorage(`forms.create_list.fill_progress.import_id`, response?.id)
                        await this.getFillBaseProgress(response?.id)
                    }
                }
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async getFillBaseProgress(import_id) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.get(`${API_ENDPOINT}/subscribers/fill-base-progress/${import_id}`)).data;
                mergeStorage(`forms.create_list.fill_progress`, response)
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async checkJobStatus() {
        return await loadingCounterWrapper(async () => {
            try {
                let { data: response, status } = (await axios.get(`${API_ENDPOINT}/subscribers/check-job`));
                if (status === 201) {
                    handleSuccess(response)
                }
                if (status === 200) {
                    putStorage(`forms.create_list.fill_progress`, response)
                }
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async getErrorReporting(id) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.get(`${API_ENDPOINT}/subscribers/error-reporting/${id}`));
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async getReminders(baseId) {
      return await loadingCounterWrapper(async () => {
        try {
          let response = (await axios.get(`${API_ENDPOINT}/subscribers/get-params/${baseId}`)).data;
          return response;
        }catch (error) {
          throw handleError(error);
        }
      });
    },

    async setReminders(baseId, payload) {
      return await loadingCounterWrapper(async () => {
        try {
          let response = (await axios.post(`${API_ENDPOINT}/subscribers/update-params/${baseId}`, payload)).data;
          handleSuccess(response);
          return response;
        }catch (error) {
          throw handleError(error);
        }
      });
    },

    async getShortcodesForRemindersEditor() {
      return await loadingCounterWrapper(async () => {
        try {
          let response = (await axios.get(`${API_ENDPOINT}/directory/pattern-fields`)).data;
          return response;
        }catch (error) {
          throw handleError(error);
        }
      })
    }

}

export default SubscribersAPI;
/*eslint-enable*/