import {handleError, loadingCounterWrapper} from "../utils/api-helper";
import axios from "axios";
import {API_ENDPOINT} from "../constants/config";
import {putStorage} from "../hooks/useStorage";

export const VersionApi = {
  async getBackendVersion() {
    return await loadingCounterWrapper(async () => {
      try {
        let payments = (await axios.get(`${API_ENDPOINT}/utility/version`)).data
        putStorage(`projectBackendVersion`, payments)
        return payments
      } catch (error) {
        throw handleError(error)
      }
    })
  }
}