
const defaultState = {
    loaderStatus: true,
    test: 23
};


const loaderReducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'SHOW_LOADER': return {...state, loaderStatus: action.payload }; break;
        case 'HIDE_LOADER': return {...state, loaderStatus: action.payload }; break;
        default: return state;
    }
}

export default loaderReducer;