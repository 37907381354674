import s from './TextInput.module.css';
import {useState} from "react";

const TextInput = (
  { value, defaultValue, inputRef, isFloatLAbelShow = true, isError = false, ...props }
) => {
  const [focus, setFocus] = useState(false);
  const focusHandler = () => {
    setFocus(true);
    if(props.onFocus) {
      props?.onFocus();
    }
  }
  const blurHandler = () => {
    setFocus(false);
    if(props.onBlur) {
      props?.onBlur()
    }
  }
  return <div className={`${s['input-box']}  ${ focus ? s['focus'] : '' } ${isError ? s['error'] : ''}`} onClick={props.onClick}>
    <label className={`${s['input-label']}`}>
      {
        isFloatLAbelShow
          ? <span className={`${s['input-float']} ${ focus || defaultValue || value || props.focused ? s['input-float--focus'] : '' }`}>
            { props.placeholder }
            </span>
          : null
      }
        <input
            value={value}
            onFocus={focusHandler}
            onBlur={blurHandler}
            onChange={props.onChange}

            className={`${s['input-field']}`} {...props}
            defaultValue={defaultValue}
            ref={inputRef}
        />
    </label>
</div>
}


export default TextInput;