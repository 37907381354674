/*eslint-disable*/
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import styled from 'styled-components'
import { Frame, H1, P, Input, Button, Checkbox, ExportButton, RowWrapper, RoundButton, convertHex } from '../ui-kit/styled-templates'

import { useStorageListener } from '../../hooks/useStorage';
import useDictionary from '../../hooks/useDictionary'

let LoadingPopUp = (props) => {
    let visible = useStorageListener(state => state.loading_counter > 0)
    let { t } = useDictionary()
    return (
        <Wrapper visible={visible} background={`grey`} >
            {t('@loading_pop_up')}
        </Wrapper>
    );
}

const Wrapper = styled(RoundButton)`
    position: fixed;
    top: 35px;
    left: 50%;
    transform: translate(-50%, ${props => props.visible ? `-50%` : `-35px`}) scale(1.2);
    z-index: 2;
    visibility: ${props => props.visible ? `visible` : `hidden`};
    opacity: ${props => props.visible ? 1 : 0};
    cursor: default;
    /* background: ${props => convertHex(props.theme.grey, 0.3)}; */
    /* color: ${props => convertHex(props.theme.grey, 1)}; */
    border: 1px solid ${props => convertHex(props.theme.grey, 1)};
    &:after {
        content: '';
        display: none;
    };
`;

export default LoadingPopUp;
/*eslint-enable*/