
export const PROJECTS_LIST = {
    tcg: 'tcg',
    sms2csclv: 'sms2csclv',
    uzit: 'uzit'
};

export const PROJECTS = {
    isTCG() {
        return window.APP_CONFIG.PROJECT_NAME === PROJECTS_LIST.tcg;
    },
    isSms2csclv() {
        return window.APP_CONFIG.PROJECT_NAME === PROJECTS_LIST.sms2csclv;
    },
    isUZIT() {
        return window.APP_CONFIG.PROJECT_NAME === PROJECTS_LIST.uzit;
    }
}