/*eslint-disable*/
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import {Switch, Route, useLocation} from "react-router-dom";
import axios from "axios";

const GuestApp = React.lazy(() => import('./guest-app'));
const AdminApp = React.lazy(() => import("./admin-app"));
const UserApp = React.lazy(() => import('./user-app'));

import UINotificationPopUp from "../pop-ups/ui-notification-pop-up";
import LoadingPopUp from "../pop-ups/loading-pop-up";

import { useStorageListener, putStorage } from "../../hooks/useStorage";

import UserAPI from "../../api/user-api";
import StatsAPI from "../../api/stats-api";
import CommonAPI from "../../api/common-api";

import useDictionary from "../../hooks/useDictionary";
import useChronicler from "../../hooks/useChronicler";
import useSpecialQueryParams from "../../hooks/useSpecialQueryParams";
import usePageTitle from "../../hooks/usePageTitle";
import useTranslate from "../../hooks/useTranslate";
import TokenHelper from "../../utils/token-helper";
import {linkTo} from "../../utils/common-helper";

const RouterApp = () => {

    /* AUTO SIGNIN */
    const {search} = useLocation()

    useEffect(() => {
        const params = new URLSearchParams(search);
        const token = params.get('tkn');
        const pathToRedirect = params.get('page') || '/';

        if(token) {
          TokenHelper.setToken(token);
          setTimeout(() => {
            UserAPI.getCurrentUser()
              .then((userData) => {
                linkTo(pathToRedirect, true);
              });
          }, 0)

        }
    }, []);
    /* END AUTO SIGNIN */

    useEffect(async () => {
        UserAPI.getCurrentUser();
        CommonAPI.getMainInfo();
    }, []);
    usePageTitle();

    const { userRole, initialized } = useStorageListener((state) => ({ userRole: state?.user?.userRole, initialized: state.initialized }));

    const route = {
        guest: GuestApp,
        admin: UserApp,
        user: UserApp,
    }[userRole] ?? GuestApp;

    const { locale } = useTranslate();

    useEffect(() => {
        axios.interceptors.request.use((config) => {
            if(!!locale) {
                config.headers.common["Accept-Language"] = locale;
            }
            return config;
        });
    }, [locale]);

    // if (userRole !== `guest`) { document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]').setAttribute("content", `white`); }

    useChronicler();
    useSpecialQueryParams();

    if (!initialized) {
        return null;
    }



    return (
        <Wrapper>
            {/*<PWAStatusBarPlaceholder />*/}
            <UINotificationPopUp />
            <LoadingPopUp />
            <React.Suspense fallback={<Loader></Loader>}>
                <Switch>
                    <Route component={route} />
                </Switch>
            </React.Suspense>
        </Wrapper>
    );
};

const PWAStatusBarPlaceholder = styled.div`
    // PWA only
    @media (display-mode: standalone) {
        width: 100vw;
        height: 44px;
    }
`;

const Wrapper = styled.div`
    background: ${(props) => props.theme.background.secondary};

    ${(props) => {
        document.body.style.background = props.theme.background.secondary;
        return ``;
    }}

    @media only screen and (max-width: 600px) {
    }

    @media (display-mode: standalone) {
    }
`;

const Loader = styled.div`
  text-align: center;
  color: #aaa;
  padding: 1em 0;
`

export default RouterApp;
/*eslint-enable*/
