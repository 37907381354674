/*eslint-disable*/
import { useEffect } from 'react'

let useKeyHandler = (key, handlerFunction, options = {}) => {
    const { avoidInputArea = true } = options;
    useEffect(() => {
        let handler = (e) => {
            if ((document.activeElement.tagName.toLowerCase() !== `input`) || !avoidInputArea) {
                if ([e.code, e.key, e.keyCode].includes(key)) {
                    e.preventDefault()
                    handlerFunction()
                }
            }
        }
        window.addEventListener(`keydown`, handler)
        return (() => {
            window.removeEventListener(`keydown`, handler)
        })
    })
}

export default useKeyHandler;
/*eslint-enable*/