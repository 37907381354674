export const PATHS = {
    home: '/campaign/sent',
    scheduled: '/campaign/scheduled',
    sent: '/campaign/sent',
    support: '/support',
    notifications: '/notifications',
    sentPreviewWithoutID: '/campaign/sent/preview/'
}

export const ENDPOINTS = {
    translateDictionaries: '/i18n'
}