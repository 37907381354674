/*eslint-disable*/
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios'

import './index.css';
import './styled-charts.css';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';

import App from './App';
//import { isna } from './utils/common-helper';
import { getToken } from './utils/token-helper';
import { LOCALES } from "./utils/locales";
import { Provider } from "react-redux";
import { store } from "./store/store";




// window.addEventListener(`update storage`, () => {
//     console.log(window.storage);
// })

window.storage = {
    user: { userRole: `guest` },
    initialized: false,
};

axios.interceptors.request.use((config) => {
    let token = getToken();
    if (token !== undefined) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.request.use((config) => {
    const lang = window.localStorage.getItem('lang');
    const locale = (lang === 'null' || !lang) ? LOCALES.Default : lang;
    config.headers["Accept-Language"] = locale;
    return config;
});

window.createId = () => Math.random().toString(36).substring(3).split(``).map(i => i[Math.random() > 0.5 ? `toLowerCase` : `toUpperCase`]()).join(``)

var swapArrayElements = function (arr, indexA, indexB) {
    var temp = arr[indexA];
    if (indexA > -1 && indexB > -1 && indexB < arr.length) {
        arr[indexA] = arr[indexB];
        arr[indexB] = temp;
    }
    return temp
};

Array.prototype.swap = function (indexA, indexB) {
    swapArrayElements(this, indexA, indexB);
    return this
};

Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
    return this
};

Array.prototype.remove = function (index) {
    if (index > -1) {
        this.splice(index, 1);
    }
    return this
};

Array.prototype.without = function (element) {
    return this.filter(i => JSON.stringify({ data: i }) !== JSON.stringify({ data: element }))
};

Array.prototype.get = function (key) {
    return this.filter(i => i.id === key)[0]
};

Array.prototype.crossing = function (anotherArray) {
    return [...this, ...anotherArray].some((i) => this.indexOf(i) > -1 && anotherArray.indexOf(i) > -1)
};

Array.prototype.unique = function () {
    return this.filter((v, i, a) => a.indexOf(v) === i)
};

Array.prototype.allIndexesOf = function (value) {
    let array = []
    this.forEach((a, b) => { if (a === value) { array.push(b) } })
    return array
};

Array.prototype.has = function (value) {
    if (this.indexOf(`*`) > -1) {
        return true
    }
    return this.indexOf(value) > -1
};

Array.prototype.roll = function (value) {
    if (isNaN(+value)) {
        console.error(`array.roll expected number, received ${typeof (value)}`);
        return this
    }
    return this.map((a, b, self) => self[(b + +value % self.length + self.length) % self.length])
}

Array.prototype.head = function (value = 5) {
    return this.slice(0, value)
}

Array.prototype.tail = function (value = 5) {
    return this.slice(-value)
}

Array.prototype.togglePush = function (value = 5) {
    return [...this, value].filter(i => this.includes(value) ? i !== value : true)
}

String.prototype.allIndexesOf = function (char) {
    let array = []
    this.split(``).forEach((a, b) => { if (a === char) { array.push(b) } })
    return array
};

String.prototype.uppercaseFirstLetter = function () {
    return this.charAt(0).toUpperCase() + this.slice(1)
};

String.prototype.isna = function () {
    return this === undefined || this === ``
};

String.prototype.toSentance = function () {
    return this.replace(/[A-Z]/g, letter => ` ${letter.toLowerCase()}`).trim().replace(`_`, ` `).replace(`-`, ` `).uppercaseFirstLetter()
};

String.prototype.toCase = function (caseName = ``) {
    let string = this
    if (string.split(``)[0] === `#`) {
        return string
    }
    switch (caseName) {
        case `upper`: string = string.toUpperCase(); break;
        case `lower`: string = string.toLowerCase(); break;
        case `upper-first`: string = string.charAt(0).toUpperCase() + string.slice(1); break;
        case `sentance`: string = string.toSentance(); break;
        case `snake`: string = string.toSentance().replaceAll(` `, `_`).toLowerCase().replaceAll(`__`, `_`); break;
        case `snake-screaming`: string = string.toSentance().replaceAll(` `, `_`).toUpperCase(); break;
        case `kebab`: string = string.toSentance().replaceAll(` `, `-`).toLowerCase(); break;
        case `kebab-screaming`: string = string.toSentance().replaceAll(` `, `-`).toUpperCase(); break;
        case `camel`: string = string.toSentance().toLowerCase().split(` `).map((i, j) => j === 0 ? i : i.uppercaseFirstLetter()).join(``); break;
        case `pascal`: string = string.toSentance().toLowerCase().split(` `).map(i => i.uppercaseFirstLetter()).join(``); break;
        default: break;
    }
    return string
};

String.prototype.has = function (substring) {
    return this.indexOf(substring) > -1
}


ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

/*eslint-enable*/