/*eslint-disable*/
import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import { Frame, Text, Image, PopUp, Textarea, Input, Checkbox, P, Button, H1, Select, RadioButton } from './styled-templates'
import Dropzone from './dropzone'
import moment from 'moment'
import InputMask from 'react-input-mask';

import countries_data from '../../constants/countries-data.json'
import { boundMomentDate, createId, isna, objectGet, validateOnlyDigits } from '../../utils/common-helper';
import mask from '../../utils/mask-helper';
import useDictionary from '../../hooks/useDictionary';

import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_blue.css";

import TextInput from "./text-input/TextInput";

const { min, max } = Math

const validateDate = (date) => {
    date = date.split(`/`)
    if (!isNaN(+date[0])) { date[0] = Math.min(31, +date[0]) }
    if (!isNaN(+date[1])) { date[1] = Math.min(12, +date[1]) }
    if (!isNaN(+date[2])) { date[2] = Math.max(+moment().format(`YYYY`), Math.min(+moment().add(100, `year`).format(`YYYY`), +date[2])) }
    return date.join(`.`)
}

const validateNumber = (string) => new RegExp(/^\d+$/).test(string);


const CustomInput = ({ value, defaultValue, inputRef, ...props }) => {
    return <input {...props} defaultValue={defaultValue} ref={inputRef} />;
};

const Form = (props) => {

    let { fields = [], onChange = () => { }, data = {}, editable = true, extra = `` } = props

    let phone_codes = countries_data.filter(i => i.phone_code !== ``).sort((a, b) => +a.phone_code - +b.phone_code).map(i => ({ value: i.phone_code, label: `${i.flag} ${i.phone_code}` }))

    let { t } = useDictionary();


    const changeDatepicker = useCallback((key, datesList) => {
        const data = datesList[0];
        onChange(key, moment(data).format('DD.MM.YYYY'))
    }, []);


    return (
        <Wrapper extra={extra} >
            {
                fields.map((item, index) => {
                    let key = (item?.name ?? createId()).toCase(`lower`).toCase(`snake`).toCase(`lower`).replace(`__`, `_`)
                    if (editable === false) {
                        return <Frame key={index} >
                            <Label>{item.name}</Label>
                            <Text extra={`margin: 0.5vw;`} bold >{data ? objectGet(data, key) || `` : ``}</Text>
                        </Frame>
                    }
                    switch (item.type) {
                        case `input`:
                        case `number`:
                            return (
                                <Input
                                    key={index}
                                    {...item}
                                    value={data ? objectGet(data, key) || `` : ``}
                                    onChange={e => { onChange(key, e.target.value) }}
                                    placeholder={item.placeholder || item.name}
                                    readonly={item.readonly} />
                            )
                        case `password`:
                            return (
                                <Input
                                    key={index}
                                    value={data ? objectGet(data, key) || `` : ``}
                                    type={`password`}
                                    onChange={e => { onChange(key, e.target.value) }}
                                    short={item.short}
                                    extra={item.extra}
                                    placeholder={item.placeholder || item.name || `Password`} />
                            )
                        case 'date':
                            if (isna(item.format)) {
                                throw new Error(`props.format not defined`)
                            }
                            return (() => {
                                let { format = `DD.MM.YYYY` } = data;
                                let [localValue, setLocalValue] = useState(``)
                                useEffect(() => {
                                    if (mask.isFullFilled(localValue, mask.create(format))) {
                                        let boundedValue = boundMomentDate(moment(localValue, format)).format(format)
                                        if (item?.min) {
                                            if(item?.useFormatDate) {
                                              boundedValue = item?.min;
                                            } else {
                                              boundedValue = moment(Math.max(+moment(boundedValue, format), +item.min)).format(format);
                                            }
                                        }
                                        if (item?.max) {
                                          if(item?.useFormatDate) {
                                            boundedValue = item?.max;
                                          } else {
                                            boundedValue = moment(Math.min(+moment(boundedValue, format), +item.max)).format(format);
                                          }
                                        }
                                        onChange(key, boundedValue)
                                        if (boundedValue !== localValue) {
                                            setLocalValue(boundedValue)
                                        }
                                    }
                                }, [localValue])
                                useEffect(() => {
                                    setLocalValue(objectGet(data, key))
                                }, [objectGet(data, key)])
                                return (
                                    <Input
                                        key={index}
                                        calendar={item?.calendar ?? true}
                                        format={format}
                                        {...props}
                                        value={localValue}
                                        onChange={e => {
                                            setLocalValue(mask.mask(e.target.value.replaceAll(/[^0-9]/g, ``), mask.create(format)));
                                        }}
                                        short={item.short}
                                        extra={item.extra}
                                        placeholder={item.placeholder || item.name}
                                    />
                                )
                            })()
                            return (
                                <InputMask key={index} mask={(item?.format ?? `99/99/9999`).replaceAll(/[^0-9.]/g, '9')} value={moment(objectGet(data, key)).format(item.format)} onChange={e => { onChange(key, !isNaN(+e.target.value) ? e.target.value : +moment(validateDate(e.target.value), item.format)) }} short={item.short} extra={item.extra} placeholder={item.placeholder || item.name} pattern={`[0-9]*`} >
                                    {(props) => <Input number calendar={item?.calendar ?? true} {...props} />}
                                </InputMask>
                            )
                        case 'phone':
                            let suffix = {
                                code: (item.name === `` ? `` : `_`) + (item?.suffix?.code ?? `code`),
                                phone: (item.name === `` ? `` : `_`) + (item?.suffix?.phone ?? `phone`),
                            };
                            return (
                                <FullWidthWrapper key={`${(item?.key || index)}__wrapper`} extra={item.extra}>
                                    <Frame key={item?.key || index} extra={`flex-direction: row !important; width: 100%!important; justify-content: flex-start;`} >
                                        <Input
                                            extra={`width: 120px; border-radius: 4px 0px 0px 4px;`}
                                            placeholder={t('@account_settings_company_page_code')}
                                            select
                                            options={phone_codes}
                                            value={data[item.name + suffix?.code]}
                                            onChange={(e) => { onChange(item.name + suffix?.[`code`], e.target.value.replaceAll(/([^0-9])/g, ``)) }}
                                            pattern={`[0-9]*`} type={`tel`} />
                                        <Input
                                            extra={`width: 100%; flex: 1; border-radius: 0px 4px 4px 0px;`}
                                            placeholder={t('@account_settings_company_page_phone')}
                                            pattern={`[0-9]*`}
                                            value={data[item.name + suffix?.phone]}
                                            onChange={(e) => {
                                                if (validateNumber(e.target.value) || e.target.value === ``) {
                                                    onChange(item.name + suffix?.[`phone`], e.target.value)
                                                }
                                            }}
                                            pattern={`[0-9]*`}
                                            type={`tel`} />
                                    </Frame>
                                    {item?.childs}
                                </FullWidthWrapper>
                            );
                        case `textarea`:
                            return (
                                <Textarea key={index} value={data ? objectGet(data, key) || `` : ``} onChange={e => { onChange(key, e.target.value) }} short={item.short} extra={item.extra} placeholder={item.placeholder || item.name} />
                            )
                        case `select`:
                            return (
                                <>
                                    <Select
                                      key={item?.key || index}
                                      {...item}
                                      placeholder={item.placeholder || item.name}
                                      value={data ? objectGet(data, key) || `` : ``}
                                      onChange={(e) => { onChange(key, e.target.value) }}
                                      autoComplete={`off`}
                                    />
                                </>

                            )
                        case `checkbox`:
                            return <P key={index} {...item} extra={`width: 100%; flex-direction: row; align-items: center !important;` + item.extra} >
                                <Checkbox
                                    checked={objectGet(data, key) === true}
                                    onChange={e => { onChange(key, objectGet(data, key) !== true) }}
                                />
                                {/* <Text extra={`margin-left: 15px; font-size: 16px; margin-bottom: 0px;`} >{item.name}</Text> */}
                                {item?.children}
                            </P>
                        case `radio-button`:
                            return <P key={index} {...item} extra={`width: 100%; flex-direction: row; align-items: center !important;` + item.extra} >
                                <RadioButton
                                    checked={objectGet(data, key) === true}
                                    onClick={e => { onChange(key, objectGet(data, key) !== true) }}
                                />
                                {item.children}
                            </P>
                        case `button`:
                            return <Button key={index} {...item} >{item?.children ?? (item?.name ?? "")}</Button>
                        case `table`:
                            return (item.content || []).map((row, row_index) => {
                                return (
                                    <Frame key={`${index}_${row_index}`} extra={`flex-direction: row; justify-content: space-between; width: 100%;`} >
                                        {
                                            (row || []).map((i, index) => {
                                                return <Frame key={index} {...i} />
                                            })
                                        }
                                    </Frame>
                                )
                            })
                        case `h1`:
                            return <H1 key={index} {...item} ></H1>
                        case `p`:
                            return <P key={index} {...item} ></P>
                        case `dropzone`:
                            return <Dropzone key={index} {...item} onDrop={(file) => { onChange(key, file) }} />
                        case `custom`:
                            return <Frame key={index} extra={item.extra} >
                                {item.children}
                            </Frame>
                        case 'datepicker':
                            return <DatepickerWrapper
                                        key={index}
                                        extra={item.extra}>
                                        <Flatpickr
                                            value={data ? objectGet(data, key) : null}
                                            options={{
                                                dateFormat: "d.m.Y",
                                                ...(item.options || {})
                                            }}
                                            onChange={(d) => changeDatepicker(key, d)}
                                            render={
                                                ({defaultValue, value, ...props}, ref) => {
                                                    return <TextInput {...item} isfocused={value} inputRef={ref} />
                                                }
                                            } />
                                    </DatepickerWrapper>
                        default: return null
                    }
                })
            }
        </Wrapper >
    )
}

const DatepickerWrapper = styled.div`
  display: block;
  ${props => props.extra}
`;

const FullWidthWrapper = styled(Frame)`
  display: inline-block;
`

let Label = styled(Text)`
    margin: 0.5vw;
    color: ${props => props.theme.text.secondary};
`

let Wrapper = styled(Frame)`
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 540px;

    > * {
        align-items: flex-start;
        margin-bottom: 20px;
        //width: 100%;
    }

    ${props => props.extra}

    @media only screen and (max-width: 600px) {
        //width: 80vw;
        margin: 0 !important;

        > * {
            //margin-bottom: 1em;
        }
    }
`;

export default Form;
/*eslint-enable*/