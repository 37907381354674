/*eslint-disable*/
import axios from 'axios'
import { API_ENDPOINT } from "../constants/config";
import { eventDispatch } from '../hooks/useEventListener';

import { putStorage } from '../hooks/useStorage'
import { handleSuccess, handleError, loadingCounterWrapper } from '../utils/api-helper'

const FileAPI = {

    async uploadFile(file, endpoint) {

        return await loadingCounterWrapper(async () => {
            try {
                const _endpoint = endpoint || '/file/upload/subscribers';
                const fd = new FormData();
                fd.append('file', file);
                let url = (await axios.post(`${API_ENDPOINT}${_endpoint}`, fd)).data.file
                return url
            } catch (error) {
                throw handleError(error)
            }
        })
    },

    async getTableByLink(url) {
        return await loadingCounterWrapper(async () => {
            try {
                let response = (await axios.post(`${API_ENDPOINT}/file/table-parse`, { url })).data;
                return response
            } catch (error) {
                throw handleError(error);
            }
        })
    },

    async exportData(options) {
        try {
            let { data: response, status } = (await axios.get(`${API_ENDPOINT}/export/index`, { params: { type: `csv`, ...options } }));
            if (status === 200) {
                window.open(response.link)
                return response
            }
            if (status === 201) {
                return handleSuccess(response)
            }
            return
        } catch (error) {
            throw handleError(error);
        }
    },
}

export default FileAPI;
/*eslint-enable*/