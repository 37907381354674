/*eslint-disable*/
import axios from 'axios'

import { API_ENDPOINT } from "../constants/config";

import { eventDispatch } from '../hooks/useEventListener';
import { mergeStorage, putStorage } from '../hooks/useStorage'
import { handleSuccess, handleError, loadingCounterWrapper, POSTOptions } from '../utils/api-helper'
import { isna } from '../utils/common-helper';

const HLRAPI = {

	async getHLR() {
		loadingCounterWrapper(async () => {
			try {
				let response = (await axios.get(`${API_ENDPOINT}/hlr`)).data;
				putStorage(`hlr_texts`, response);
				return response
			} catch (error) {
				throw handleError(error);
			}
		})
	},

	async HLRCheck(phones) {
		loadingCounterWrapper(async () => {
			try {
				let response = (await axios.post(`${API_ENDPOINT}/hlr/check`, { phones })).data;
				putStorage(`hlr_check_response`, response)
				return response
			} catch (error) {
				throw handleError(error);
			}
		})
	},

	async getHLRStats(options) {
		loadingCounterWrapper(async () => {
			try {
				let response = (await axios.post(`${API_ENDPOINT}/hlr/stats`, POSTOptions(options))).data;
				putStorage(`stats.hlr`, response)
				return response
			} catch (error) {
				throw handleError(error);
			}
		})
	},
    async getHLRSCheckBase(options, addParams) {
			await loadingCounterWrapper(async () => {
				try {
					let response = (await axios.post(`${API_ENDPOINT}/hlr/check-base/${addParams.id}`, POSTOptions(options))).data;
					putStorage(`check_base.hlr`, response)
					return response
				} catch (error) {
					throw handleError(error);
				}
			})
    },
};

export default HLRAPI;
/*eslint-enable*/