/*eslint-disable*/
//const currentProtocol = window?.location?.protocol;
export const API_ENDPOINT = window.APP_CONFIG.API_ENDPOINT;//`${process.env.REACT_APP_API_ENDPOINT}`;
export const PROJECT_NAME = window.APP_CONFIG.PROJECT_NAME;

export const ticketTypes = {
    1: "Technical question",
    2: "Financial issue",
    3: "Other",
    4: "Delivery issues"
};
/*eslint-enable*/
