/*eslint-disable*/
import React, {useState, useEffect, useMemo, useCallback, useRef} from 'react';

import moment from 'moment'

import { Frame, H1, P, Button, Checkbox } from '../ui-kit/styled-templates'
import Form from '../ui-kit/form'
import PopUpWrapper from './pop-up-wrapper'
import { isna } from '../../utils/common-helper'

import useEventListener, { eventDispatch } from '../../hooks/useEventListener'
import useDictionary from '../../hooks/useDictionary'
import SubscribersAPI from '../../api/subscribers-api';

let EditContactPopUp = (props) => {

    let [list, setList] = useState({});
    let [data, setData] = useState({});
    let [contactFormVisible, setContactFormVisible] = useState(false);

    let [equalNumbersStatus, setEqualNumbersStatus] = useState(true);

    let [referencePhoneNumber, setReferencePhoneNumber] = useState('')


    const successPhoneChange = () => {
        setReferencePhoneNumber(data.number);
    }

    useEventListener(`OPEN_EDIT_CONTACT_POP_UP`, (d) => {
        console.log(d)
        setData({
            ...d.detail.contact,
            male: d.detail.contact.gender.toLowerCase() === `male`,
            female: d.detail.contact.gender.toLowerCase() === `female`,
        });
        setList(d.detail.list);

        setReferencePhoneNumber(d.detail.contact.number);
    });

    useEffect(() => {
        setEqualNumbersStatus(referencePhoneNumber === data.number)
    }, [data.number, referencePhoneNumber]);

    let { t } = useDictionary();

    return (
        <PopUpWrapper
            name={`EDIT_CONTACT`}
            extra={`width: 430px; `}
            closeByClickOutside={false}
        >
            <H1 extra={`margin-bottom: 14px;`} >{t('@edit_contact_pop_up_0')}</H1>
            <P extra={props => `color: ${data.status === `Active` ? props.theme.green : props.theme.red} !important;`} >{data.status}</P>
            <P>{t('@edit_contact_pop_up_1')}{data.created}</P>
            <P extra={`margin-bottom: 20px;`} >{t('@edit_contact_pop_up_2')}{data.last_delivery}</P>
            <Frame extra={`
                flex-direction: row; 
                justify-content: 
                space-between; width: 100%
            `}>
                <Form
                    data={data}
                    onChange={(key, value) => { setData({ ...data, [key]: value }) }}
                    extra={`width: 100%`}
                    fields={[
                        {
                            type: `input`,
                            placeholder: t('@edit_contact_pop_up_3'), name: `number`,

                        },
                    ]}
                />
            </Frame>
            {
                !equalNumbersStatus
                ?
                    <Frame extra={`
                        flex-direction: row; 
                        justify-content: flex-start; 
                        width: 100%;
                        margin-bottom: 1em;
                        border: 1px solid var(--accent-color-primary);
                        padding: 1em;
                        border-radius: 5px;
                        box-sizing: border-box;
                    `}>
                        <P extra={`margin-right: 1em; font-size: 0.7em`}>{t('@edit_contact_pop_up_12')}</P>
                        <Button
                            onClick={successPhoneChange}
                            background={'var(--accent-color-primary)'}
                            extra={`line-height: 16px`}>
                            {t('@edit_contact_pop_up_13')}
                        </Button>
                    </Frame>
                : ''
            }


            <Frame extra={`width: 100%; align-items: flex-start; margin-bottom: 5px;`} >
                <P
                    onClick={() => { setContactFormVisible(!contactFormVisible) }}
                    extra={`cursor: pointer;`} >
                    <Checkbox checked={contactFormVisible} />
                    {t('@edit_contact_pop_up_4')}
                </P>
            </Frame>
            {contactFormVisible ? <ContactForm data={data} onChange={setData} t={t} /> : null}
            <Frame row extra={`width: 100%;`} >
                <Button extra={props => `width: 180px; @media (max-width: 600px) { width: 50%; }; border: 0px !important; color: ${props.theme.text.primary} !important;`} onClick={() => { eventDispatch(`CLOSE_EDIT_CONTACT_POP_UP`) }} shaped >{t('@pop_up_cancel')}</Button>
                <Button
                    extra={`width: 180px; @media (max-width: 600px) { width: 50%; };`}
                    onClick={async () => {
                        let o = JSON.parse(JSON.stringify(data));
                        if (o.male || o.female) {
                            o.gender = o.male === true ? `male` : `female`
                        }
                        if (!contactFormVisible) {
                            o = { number: o.number, id: o.id };
                        }
                        // TODO: Fix this after API appears
                        await SubscribersAPI.addContactToBase({ listId: list.id, ...o, phone: o.number });
                        eventDispatch(`CLOSE_EDIT_CONTACT_POP_UP`)
                    }}
                    disabled={isna(data[`number`]) || isna(list.id) || !equalNumbersStatus}
                >
                    {t('@pop_up_save')}
                </Button>
            </Frame>
        </PopUpWrapper>
    );
}

export let ContactForm = (props) => {
    let { data = {}, onChange = async () => { }, t = () => { } } = props;
    return (
        <Form
            data={data}
            onChange={(key, value) => {
                let o = {};
                if (key === `male` && value === true) { o = { male: true, female: false } };
                if (key === `female` && value === true) { o = { male: false, female: true } };
                onChange({ ...data, [key]: value, ...o })
            }}
            extra={`width: 100%; margin-top: 25px; > * { margin-bottom: 25px; };`}
            fields={[
                { type: `input`, placeholder: t('@edit_contact_pop_up_5'), name: `email`, extra: `width: 100%;`, },
                { type: `input`, placeholder: t('@edit_contact_pop_up_6'), name: `first_name`, extra: `width: 100%;`, },
                { type: `input`, placeholder: t('@edit_contact_pop_up_7'), name: `middle_name`, extra: `width: calc((100% - 10px) / 2) !important;`, },
                { type: `input`, placeholder: t('@edit_contact_pop_up_8'), name: `last_name`, extra: `width: calc((100% - 10px) / 2) !important;`, },
                {
                    type: `datepicker`,
                    placeholder: t('@edit_contact_pop_up_9'),
                    name: `birth_day`,
                    format: `DD.MM.YYYY`,
                    extra: `width: calc((100% - 10px) / 2) !important;`,
                    options: {
                        maxDate: moment().format('DD.MM.YYYY')
                    }
                },
                { type: `radio-button`, placeholder: t('@edit_contact_pop_up_10'), name: `male`, children: t('@gender_male'), extra: `width: 24% !important`, },
                { type: `radio-button`, placeholder: t('@edit_contact_pop_up_11'), name: `female`, children: t('@gender_female'), extra: `width: 24% !important`, },
            ]}
        />
    )
}

export default EditContactPopUp;
/*eslint-enable*/