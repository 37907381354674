/*eslint-disable*/
import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import { createId } from '../../utils/common-helper'

import useOnClickOutside from '../../hooks/useOnClickOutside'
import useElementBounding from "../../hooks/useElementBounding";
import useDictionary from "../../hooks/useDictionary";

let Dropdown = (props) => {

    let { options = [], isFixed = false, dropDownStyles } = props

    let [visible, setVisible] = useState(false)
    let [confirm, setConfirm] = useState(undefined)
    let uniqueClassName = useRef(createId());

    let { t } = useDictionary();

    let ref = useRef();

    let caller = useRef();

    let wrapperRef = useRef();

    useOnClickOutside(wrapperRef, (e) => {
        if (!((e.path || []).map(i => (i.className || ``)).map(i => typeof (i) === `string` ? i : (i.baseVal || ``))).map(i => i.includes(uniqueClassName.current)).reduce((a, b) => a || b, false)) {
            setVisible(false);
        }
    })

    useEffect(() => { setConfirm(undefined) }, [visible]);

    let { bounding } = useElementBounding(caller);

    let dropperBounding = ref.current?.getBoundingClientRect() || {width: 0};

    const [callerPageX, setCallerPageX] = useState(0)

    const clickOnCaller = (e) => {
        setCallerPageX(e.pageX);
        setVisible(!visible)
    }


    return (
        <Frame ref={wrapperRef} extra={dropDownStyles} data-el-id={`dropdown-wrapper`} className={uniqueClassName.current} >
            <Frame
                data-el-id={`dropdown-caller`}
                onClick={clickOnCaller}
                extra={`cursor: pointer;`}
                ref={caller}
            >
                {props.children}
            </Frame>
            <DroppedOut
              data-element={'dropped-out'}
              visible={visible}
              extra={`
                    ${props?.dropDownStyles ?? ``} ${isFixed
                ? ` position: fixed; 
                            left: ${Math.min(bounding.left, callerPageX) - dropperBounding.width}px; 
                            top: ${bounding.top + bounding.height}px; 
                            right: auto;`
                : ''
              }`}
            >

            <DroppedFrame
                data-el-id={`dropdown-container`}
                ref={ref}
                visible={visible}
                // extra={`
                //     ${props?.dropDownStyles ?? ``} ${isFixed
                //         ? ` position: fixed;
                //             left: ${Math.min(bounding.left, callerPageX) - dropperBounding.width}px;
                //             top: ${bounding.top + bounding.height}px;
                //             right: auto;`
                //         : ''
                // }`}
            >
                {
                    props.custom
                        ? props.custom
                        : <>
                            {
                                options.map((item, index) => {
                                    return (
                                        <Item key={index + '__'} {...item} hoverless={props.hoverless === true} id={`dropdown-item`} onClick={() => {
                                            if (item.confirm === true) {
                                                setConfirm(index)
                                            } else {
                                                if (props.onClick !== undefined) {
                                                    props.onClick(item)
                                                    setVisible(false)
                                                } else {
                                                    if (item.onClick !== undefined) {
                                                        item.onClick(item)
                                                        setVisible(false)
                                                    }
                                                }
                                            }
                                        }} >
                                            {
                                                confirm === index ? <Confirm onClick={() => {
                                                    if (props.onClick !== undefined) {
                                                        props.onClick()
                                                        setVisible(false)
                                                    }
                                                }} /> : item.label || item.children
                                            }
                                        </Item>
                                    )
                                })
                            }
                            {
                                options.length === 0
                                    ? <NoOptionsPlaceholder>{ t('@no_options_label') }</NoOptionsPlaceholder>
                                    : null}
                        </>
                }
            </DroppedFrame>
            </DroppedOut>
        </Frame>
    )
}

const Frame = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    transition: 0.2s;
    ${props => props.extra}
`;

const NoOptionsPlaceholder = styled.div`
    width: 100px;
    color: ${props => props.theme.text.secondary};
    font-weight: 500;
    &:after {
        content: '';
        color: ${props => props.theme.text.secondary};
        font-weight: 500;
    };
`;

const P = styled(Frame)`
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: ${props => props.theme.text.secondary};
    cursor: pointer;

    @media (min-width: 320px) and (max-width: 480px) {
        font-size: 3.75vw;
        line-height: 6vw;
    }
`;


const DroppedOut = styled.div`
  overflow-y: scroll;
  opacity: ${props => props.visible ? 1 : 0};
  position: absolute;
  right: 0;
  top: calc(100% - ${props => props.visible ? 0 : 15}px + 6px);
  max-height: 350px;
  box-shadow: 0px 0px 5px rgba(0, 107, 168, 0.05), 0px 25px 35px rgba(0, 107, 168, 0.13);
  border-radius: 4px;
  background: ${props => props.theme.background.primary};
  z-index: 2;
  //::-webkit-scrollbar {
  //     width: 0;
  //   }
  visibility: ${props => props.visible ? `visible` : `hidden`};
  ${props => props.extra}
`

const DroppedFrame = styled(Frame)`
    //position: absolute;
    display: block;
    //background: ${props => props.theme.background.primary};
    ${props => props.theme.shadow.small}
    border-radius: 12px;
    padding: 17px 20px;
    //box-shadow: 0px 0px 5px rgba(0, 107, 168, 0.05), 0px 25px 35px rgba(0, 107, 168, 0.13);
    //border-radius: 4px;
    visibility: ${props => props.visible ? `visible` : `hidden`};
    //opacity: ${props => props.visible ? 1 : 0};
    align-items: flex-start;
    //right: 0%;
    //top: calc(100% - ${props => props.visible ? 0 : 15}px + 6px);
    //z-index: 2;
    //max-height: 350px;
    //overflow-y: scroll;
    > * {
        margin-bottom: 15px;
        //transform: scale(${props => props.visible ? 1 : 0.8});
        &:last-child {
            margin-bottom: 0px;
        }
    }
    //::-webkit-scrollbar {
    //    width: 0;
    //}

    ${props => props.extra}
`;

const Item = styled(Frame)`
    font-size: 14px;
    /* color: ${props => props.color || props.theme.text.primary}; */
    /* font-weight: ${props => props.color ? `bold` : `500`}; */
    color: ${props => props.color || props.theme.text.primary} !important;
    cursor: ${props => props.disabled || props.hoverless ? `default` : `pointer`};
    opacity: ${props => props.disabled ? 0.3 : 1};
    min-width: 100px;
    font-weight: 400 !important;
    margin-bottom: 10px;
    align-items: flex-start;

    ${props => props.hoverless ? `` : css`
        &:hover {
            opacity: ${props.disabled ? 0.3 : 0.8};
            transform: translateX(${props.disabled ? 0 : -2}px);
        }
    `}
    ${props => props.extra}
`;

const Confirm = styled(Frame)`
    &:after {
        content: 'Confirm';
        color: ${props => props.theme.yellow};
    }
`;

export default Dropdown;
/*eslint-enable*/