/*eslint-disable*/
import { useState, useEffect } from 'react';

import useEventListener, { eventDispatch } from './useEventListener';

import { objectPut, objectMerge, createId, isna, objectGet, path, objectCopy } from '../utils/common-helper';

export let useStorageListener = (getPathContent) => {
    if (typeof getPathContent === `string`) {
        getPathContent = path(getPathContent);
    }
    let [state, setState] = useState(objectCopy(getPathContent(window.storage)));

    useEventListener(`update storage`, () => {
        if (JSON.stringify(getPathContent(window.storage)) !== JSON.stringify(state)) {
            setState(objectCopy(getPathContent(window.storage)));
        }
    });
    return state;
};

export let putStorage = (path, value) => {
    //console.log(window.storage, path, objectCopy(value))
    objectPut(window.storage, path, objectCopy(value));
    // localStorage.setItem(`storage`, JSON.stringify(window.storage));
    eventDispatch(`update storage`);
};

export let mergeStorage = async (path, value) => {
    objectMerge(window.storage, path, value);
    eventDispatch(`update storage`);
};

export let getStorage = (path = (state) => state) => {
    if (typeof path !== `function`) {
        throw new Error(`useStorage (getStorage): path is not a function`);
    }
    return path(window.storage);
};

let getNested = (obj, ...args) => {
    return args.reduce((obj, level) => obj && obj[level], obj);
};

export let useStorageValue = (initValue, path, useInitialized) => {
    path = path || `temp.` + createId();

    if (isna(getNested(window.storage, ...path.split(`.`)))) {
        objectPut(window.storage, path, initValue);
    }else{
      if(useInitialized) {
        const o = [...path.split(`.`)].reverse().reduce((acc, r) => {
          acc = !!acc[r] ? {...acc} : {[r]: {...acc}};
          return acc;
        }, {});

        objectPut(window.storage, path, o);
        objectPut(window.storage, path, initValue);
      }
    }


    let value = useStorageListener((state) => getNested(state, ...path.split(`.`)));

    //console.log('value', value)

    let setMethod = (value) => {
        //console.log('set', path, value)
        putStorage(path, value);
    };

    useEffect(() => {
        return () => {
            // TODO: Add cleanup function
        };
    }, []);

    return [value, setMethod, path];
};

// export default useStorageListener;
/*eslint-enable*/
