import {useEffect, useState} from "react";

import CommonHelper from '../utils/common-helper';


const useElementBounding = (elementRef) => {

    let [bounding, setBounding] = useState({});


    useEffect(() => {

        function getBounding() {
            return elementRef.current.getBoundingClientRect()
        }

        setBounding(getBounding());

        const scroller = () => {
            if(elementRef.current) {
                setBounding(getBounding());
            }
        }

        const throttlingScroller = CommonHelper.throttle(scroller, 200);

        document.addEventListener("scroll", throttlingScroller);
        window.addEventListener("resize", throttlingScroller);

        return () => {
            document.removeEventListener('scroll', throttlingScroller);
            window.removeEventListener("resize", throttlingScroller);
        }
    }, [elementRef]);

    return {bounding};
}

export default useElementBounding;