import axios from "axios";
import {ENDPOINTS} from "../utils/urls";
import {handleError, loadingCounterWrapper} from "../utils/api-helper";



export const getTranslateDictByCode = (code) => {
    return loadingCounterWrapper(async () => {
        try {
            const response = await axios.get(`${ENDPOINTS.translateDictionaries}/${code}.json?v=40`)
            return response.data;
        } catch (error) {
            throw handleError(error);
        }
    });
}

export const getTranslateItemByKey = (key) => {
    const dict = window?.storage?.i18nDict;
    return dict[key] || 'i18n error:  translate not found';
}