/*eslint-disable*/
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components'
import axios from 'axios'

import { Frame } from '../ui-kit/styled-templates'

import { eventDispatch } from '../../hooks/useEventListener'
import useDictionary from "../../hooks/useDictionary";

let Dropzone = (props) => {

    let {
      onUpload = async () => { },
      onDrop = async () => { },
      accept = `image/jpeg`,
      clickable = true,
      extra = ``
    } = props;

    let ref = useRef();

    let {t} = useDictionary();

    let ultimate = async (e) => {
        e.preventDefault();
        let files = e?.dataTransfer?.files || e?.target?.files;
        if (files) {
            for (let i = 0; i < files.length; i++) {
                let file = files[0];
                //if (accept.includes(file.type)) {
                    //eventDispatch(`THROW_ERROR`, t('@file_extension_is_not_acceptable'))
                    //return
                //}
                onDrop(file)
                // let url = await FileAPI.uploadFile(file)
                // onUpload(url)
            }
        }
    }

    return (
        <Wrapper onDrop={ultimate} onDragOver={(e) => { e.preventDefault() }} onClick={() => { if (clickable) { ref.current.click() } }} accept={accept} extra={(clickable ? `` : `cursor: default;`) + extra} >
            {clickable && <Input ref={ref} type={`file`} onChange={ultimate} />}
            {props.children}
        </Wrapper>
    );
}

const Input = styled.input`
    display: none;
`;

const Wrapper = styled(Frame)`
    cursor: copy;

    ${props => props.extra}
`;

export default Dropzone;
/*eslint-enable*/