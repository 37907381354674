/*eslint-disable*/
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import moment from 'moment-timezone'

import { Frame, H1, P, Input, Button, Checkbox, ExportButton, RowWrapper, PageHeader } from '../ui-kit/styled-templates'
import PopUpWrapper from './pop-up-wrapper'
import { ContactForm } from './edit-contact-pop-up'

import { createId, sleep } from '../../utils/common-helper'

import useEventListener, { eventDispatch } from '../../hooks/useEventListener'
import { scryRenderedComponentsWithType } from 'react-dom/cjs/react-dom-test-utils.development';

let UINotificationPopUp = (props) => {

    let notificationsRef = useRef([])
    let [notifications, setNotifications] = useState(notificationsRef.current)

    useEffect(() => { notificationsRef.current = notifications }, [notifications])

    let addItem = (item, type) => {
        let id = createId()
        if (![`success`, `error`].includes(type)) {
            throw new Error(`UINotificationPopUp: type is not in ["success", "error"]`)
        }
        setNotifications([...notifications, { id: id, message: item, visible: false, status: type }])
        return id
    }

    let openItem = (item_id) => {
        setNotifications(notificationsRef.current.map(i => i.id === item_id ? { ...i, visible: true } : i))
    }

    let closeItem = (item_id) => {
        setNotifications(notificationsRef.current.map(i => i.id === item_id ? { ...i, visible: false } : i))
    }

    useEventListener(`THROW_ERROR`, async (d) => {
        let id = addItem(d.detail, `error`)
        await sleep(0); openItem(id)
        await sleep(5000); closeItem(id)
    })

    useEventListener(`THROW_SUCCESS`, async (d) => {
        let id = addItem(d.detail, `success`)
        await sleep(0); openItem(id)
        await sleep(5000); closeItem(id)
    })

    return (
        <>
            {
                notifications.map((item, index, self) => <Bar key={index} onClick={() => { }} status={item.status} visible={item.visible} index={notifications.filter(i => i.visible || i.id === item.id).map(i => i.id).indexOf(item.id)} >
                    <Frame extra={`align-items: flex-start;`} >
                        {item.message.split(`\\n`).map((item, index) => {
                            return (
                                <span key={index} >
                                    {item}
                                </span>
                            )
                        })}
                    </Frame>
                    <Cros onClick={() => { closeItem(item.id) }} />
                </Bar>)
            }
        </>
    );
}

const Cros = styled(Frame)`
    width: 18px;
    height: 18px;
    background: url('${require(`../../assets/icons/cross.svg`).default}') no-repeat center center / contain;
    margin-left: 9px;
    cursor: pointer;
`;

const Bar = styled(Frame)`
    padding: 15px;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;

    background: ${props => props.theme.background.primary};
    box-shadow: 0px 10px 20px rgba(0, 155, 232, 0.1);
    border-radius: 4px 0px 0px 4px;

    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.6px;
    font-weight: 500;
    color: ${props => props.theme.text.primary};

    transition: 0.5s;
    transform: translate(${props => props.visible ? 0 : 100}%, ${props => `calc(${props.index * 100}% + ${props.index * 10}px)`});
    visibility: ${props => props.visible ? `visible` : `hidden`};
    opacity: ${props => props.visible ? 1 : 0};

    z-index: 4;
    position: fixed;
    top: 0;
    right: 0;

    &:before {
        content: '';
        width: 43px;
        height: 43px;
        background: url('${props => require(`../../assets/icons/ui-status-${props.status}.svg`).default}') no-repeat center center / contain;
        margin-right: 9px;
    };
`;

export default UINotificationPopUp;
/*eslint-enable*/