import {useEffect, useState} from "react";
import {getTranslateDictByCode} from "../api/translate-api-service";
import {putStorage} from "./useStorage";
import {LOCALES} from "../utils/locales";

const useTranslate = () => {
  const lang = window.localStorage.getItem('lang');
  const currentLang = (lang === 'null' || !lang) ? LOCALES.Default : lang;
  const [translate, setTranslate] = useState({});
  const [locale, setLocale] = useState(currentLang);
  const [loadTranslateDict, setLoadTranslateDict] = useState(false);

  let changeTranslate = (locale) => {
    setLocale(locale);
  }

  useEffect(() => {
    putStorage(`locale.lang`, locale);
    localStorage.setItem(`lang`, locale);
    getTranslateDictByCode(locale)
      .then((data) => {
        setTranslate(data);
      }).finally(() => {
      setLoadTranslateDict(true)
    })
  }, [locale]);

  return {
    locale,
    translate,
    loadTranslateDict,
    changeTranslate
  };
}

export default useTranslate;