/*eslint-disable*/
import React, { useState, useEffect } from 'react'
import styled, { ThemeProvider, css } from 'styled-components'
import { Link as ImportLink } from 'react-router-dom'


import { darkTheme, dayTheme } from '../../constants/style-constants'
import ImportInput from './input'
import ImportButton from './button'
import ImportDropdown from './dropdown'

import ImportWorldMapChart from '../tools/world-map-chard'

import useDictionary from '../../hooks/useDictionary'

import FileAPI from '../../api/file-api'
import { isna } from '../../utils/common-helper'

let parseLinks = (string) => {
    let splitters = string.allIndexesOf(`|`)
    if (splitters.length > 0) {
        splitters.forEach(i => {
            splitters = string.allIndexesOf(`|`)
            let [a, x, b] = [string.indexOf(`[`), splitters[0], string.indexOf(`]`)]
            if (a > -1, x > a, b > x) {
                let link = string.slice(a + 1, x)
                let text = string.slice(x + 1, b)
                string = string.replace(string.slice(a, b + 1), `<a href="${link.indexOf(`http://`) === -1 && link.indexOf(`https://`) === -1 ? `https://` : ``}${link}" target="_blank" >${text}</a>`)
            }
        })
    }
    if (string.indexOf(`<a href`) > -1) {
        string = `<div style="display: inline-block;" >` + string + `</div>`
    }
    return string
}

export const Input = (props) => <ImportInput {...props} />
export const Button = (props) => <ImportButton {...props} />
export const Textarea = (props) => <ImportInput textarea {...props} />
export const Dropdown = (props) => <ImportDropdown {...props} />
export const Select = (props) => <ImportInput select {...props} />
export const WorldMapChart = (props) => <ImportWorldMapChart {...props} />

export const Link = (props) => {
    let { withoutScroll = false } = props;
    return <ImportLink
        style={{ textDecoration: 'none', [`:hover`]: { textDecoration: `none` } }}
        {...props}
        onClick={() => {
            if (!withoutScroll) {
                try { document.getElementById(`scrollWrapper`).scrollTo({ top: 0, behavior: "smooth", }); } catch (error) { }
                try { window.scrollTo({ top: 0, behavior: "smooth", }); } catch (error) { }
                if (props.onClick !== undefined) { props.onClick() }
            }
        }}
    />
}

export const Frame = styled.div.attrs((props) => {
    return {
        ...props,
        dangerouslySetInnerHTML: typeof (props.children) === `input` ? { __html: parseLinks(props.children) } : undefined,
        children: typeof (props.children) === `input` ? undefined : props.children,
    }
})`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: ${props => props.row ? `row` : `column`};
    transition: 0.2s;
    font-size: 18px;
    @media (min-width: 320px) and (max-width: 480px) {
        font-size: 4vw;
        /* transition: 0s; */
    }
    ${props => props.extra}
`

const CheckboxWrapper = styled(Frame)`
    height: 24px;
    width: 24px;
    border: 1px solid ${props => props.dark ? props.theme.text.dark : props.checked ? props.theme.blue : props.theme.grey};
    background: ${props => props.checked && props.dark ? props.theme.text.dark : `transparent`};
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    ${props => props.extra}
`;

const CheckboxContent = styled(Frame)`
    width: 10px;
    height: ${props => props.dark ? 1 : 10}px;
    opacity: ${props => +props.checked};
    background: ${props => props.dark ? `#FFFFFF` : css`url("${require(`../../assets/icons/checkbox-content.svg`).default}") no-repeat center center / contain`};
    transform: translateY(${props => props.checked ? 0 : 5}px);
`;

export const Checkbox = (props) => {
    let { checked = false, onChange = () => { }, dark = false } = props
    return (
        <CheckboxWrapper extra={props.extra} onClick={() => { onChange(!checked) }} checked={checked} dark={dark} >
            <CheckboxContent checked={checked} dark={dark} />
        </CheckboxWrapper>
    )
}

export const Text = styled(Frame)`
    // font-size: ${props => props.size ? props.size : 0.8}vw;
    font-size: ${props => props.size ? props.size : 16}px;
    color: ${props => props.text_color ? props.text_color : props.theme.text.primary};
    white-space: pre-wrap;
    ${props => props.extra}
    @media (min-width: 320px) and (max-width: 480px) {
        font-size: ${props => (props.size ? props.size : 0.8) * 4}vw;
    }
`

export const H1 = styled(Frame)`
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    
    color: ${props => props.theme.text.primary};
    
    @media (min-width: 320px) and (max-width: 480px) {
        font-size: 7vw;
        line-height: 9.3vw;
    }

    ${props => props.extra}
`;

export const P = styled(Frame)`
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 22px;
    flex-direction: row;
    justify-content: flex-start;

    color: ${props => props.theme.text.dark};

    @media (min-width: 320px) and (max-width: 480px) {
        font-size: 3.75vw;
        line-height: 6vw;
    }

    ${props => props.extra}
`;

export const H2 = styled(Frame)`
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 23px;

    color: ${props => props.theme.text.primary};

    ${props => props.light ? `letter-spacing: -0.6px;` : ``}

    // mobile devices only
    @media (max-width: 480px) {
        font-size: 5vw;
        line-height: 5.25vw;
    }

    ${props => props.extra}
`;

export const H3 = styled(P)`
    font-size: 18px;
    margin-bottom: 30px;

    ${props => props.extra}
`;

export const Info = styled(Frame)`
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;

    color: ${props => props.theme.text.primary};

    // mobile devices only
    @media (max-width: 480px) {
        font-size: 14px;
        line-height: 22px;
    }

    ${props => props.extra}
`;

export const Card = styled(Frame)`
    padding: 35px 30px;
    border-radius: 14px;
    background: ${props => props.theme.background.primary};

    ${props => props.theme.shadow.card};

    @media (max-width: 480px) {
        padding: 16px;
        width: calc(90vw - 16px * 2);
    }

`;

const ThemeWrapper_ = (props) => {
    let default_theme = props.theme === `light` ? dayTheme : darkTheme
    let [theme, setTheme] = useState(default_theme)
    useEffect(() => {
        if (localStorage.getItem(`theme_shift`) === `system` || true) {
            if (window.matchMedia(`(prefers-color-scheme: dark)`).matches) {
                props.set_theme(`dark`)
            } else {
                props.set_theme(`light`)
            }
            try {
                window.matchMedia(`(prefers-color-scheme: dark)`).addEventListener(`change`, () => {
                    if (localStorage.getItem(`theme_shift`) === `system` || true) {
                        if (window.matchMedia(`(prefers-color-scheme: dark)`).matches) {
                            props.set_theme(`dark`)
                        } else {
                            props.set_theme(`light`)
                        }
                    }
                }, false);
            } catch (error) {
                console.log(error)
            }
        }
    }, [props.theme_shift])
    useEffect(() => {
        let new_theme = dayTheme
        if (props.theme === `light`) new_theme = dayTheme
        if (props.theme === `dark`) new_theme = darkTheme
        setTheme({ ...new_theme, name: props.theme })
    }, [props.theme])
    return (
        <ThemeProvider theme={dayTheme}>
            {props.children}
        </ThemeProvider>
    )
}

export let ThemeWrapper = (props) => {
    let [theme, set_theme] = useState(`light`)
    return (
        <ThemeWrapper_ theme={theme} set_theme={(data) => { set_theme(data) }} >
            {props.children}
        </ThemeWrapper_>
    )
}

export let Range = (props) => {
    let { value = 0, onChange = () => { } } = props
    return (
        <Input
            type={`range`}
            value={+value}
            min={0}
            max={1}
            step={0.01}
            extra={`margin: 1vw; width: 10vw; height: 0.2vw; padding: 0;`}
            onChange={(e) => { onChange(e.target.value) }}
        />
    )
}

const SwitchWrapper = styled(Frame)`
    position: relative;
    width: 2.25em;
    height: 1.25em;
    border-radius: 1em;
    background: ${({ theme, checked = false }) => (checked ? theme.green : theme.grey)};
    cursor: ${({ disabled = false }) => (disabled ? `default` : `pointer`)};
    &:after {
        content: "";
        transition: 0.2s;
        width: 1em;
        height: 1em;
        background: white;
        border-radius: 1em;
        position: absolute;
        left: ${({checked = false}) => checked ? 1.125 : 0.125}em;
    }
`;

export const Switch = (props) => {
    const { name = `checkbox`, extra = ``, checked = false, onChange = () => { } } = props;
    return <SwitchWrapper onClick={onChange} checked={checked} />;
};

export let colorToChanels = (color = `#000000`) => {
    if (color.indexOf(`#`) > -1) {
        let hex = color.replace('#', '');
        return [
            parseInt(hex.substring(0, 2), 16),
            parseInt(hex.substring(2, 4), 16),
            parseInt(hex.substring(4, 6), 16)
        ]
    }
    return color.replace(`rgba`, ``).replace(`rgb`, ``).replace(` `, ``).replace(`(`, ``).replace(`)`, ``).split(`,`).map(i => +i).slice(0, 3)
}

export let hexToRGB = (hex = `#000000`,) => {
    let [r, g, b] = colorToChanels(hex)
    return 'rgb(' + r + ',' + g + ',' + b + ')';
}

export let convertHex = (hex = `#000000`, opacity = 1) => {
    let [r, g, b] = colorToChanels(hex)
    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
}

let componentToHex = (c) => c.toString(16).length == 1 ? "0" + c.toString(16) : c.toString(16);
export let rgbToHex = (r, g, b) => (`#` + componentToHex(r) + componentToHex(g) + componentToHex(b)).toUpperCase();

export let darkerHex = (hex, reverseOpacity = 0.1) => {
    let [r, g, b] = colorToChanels(hex).map(i => Math.round(i * (1 - reverseOpacity)))
    return rgbToHex(r, g, b)
}

export const Tab = styled(Frame)`
    background: ${props => props.theme.background.primary};
    border-radius: 2px;
    padding: 20px 20px 40px 20px;
    box-sizing: border-box;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 40px;

    // mobile devices only
    @media (max-width: 600px) {
        width: 90vw;
        padding: 5vw 3vw;
    }

    // tablts
    @media (min-height: 600px) and (max-width: 1024px) {
        width: 100%;
        padding: 28px 22px;
    }

    ${props => props.extra}
`;

export const RadioButton = styled(Frame)`
    height: 24px;
    min-width: 24px;
    border-radius: 50%;
    border: 1px solid ${props => props.checked ? props.theme.blue : props.theme.grey};
    box-sizing: border-box;
    margin-right: 5px;
    cursor: pointer;

    &:after {
        content: '';
        width: 12px;
        height: 12px;
        background: ${props => props.theme.blue};
        border-radius: 50%;
        /* transform: scale(${props => props.checked ? 1 : 0}); */
        opacity: ${props => props.checked ? 1 : 0};
        transition: 0.2s;
    };
`;

export const SquareButton = styled(Frame)`
    width: 44px;
    height: 44px;
    border: 1px solid ${props => props.theme.text.dark};
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;

    &:after {
        content: '';
        width: 24px;
        height: 24px;
        background: url('${props => require(`../../assets/icons/${props.icon || `options`}.svg`).default}') no-repeat center center / contain;
    };

    ${props => props.extra}
`;


export const RoundButton = styled(Frame)`
    background: ${props => convertHex(props.theme[props.background || `orange`], 0.15)};
    border: 1px solid ${props => convertHex(props.theme[props.background || `orange`], 0.15)};
    box-sizing: border-box;
    border-radius: 50px;
    padding: 7px 15px;
    box-sizing: border-box;

    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.062857px;
    color: ${props => props.theme[props.background || `orange`]} !important;
    cursor: pointer;
    flex-direction: row;

    &:hover:after {
        transform: translateX(5px);
    }

    &:after {
        content: '';
        width: 8px;
        height: 11px;
        /* margin: 2.5px 0px 0px 10px; */
        margin-left: 10px;
        transition: 0.2s;
        background: url('${props => require(`../../assets/icons/arrow-right-${props.background || `orange`}.svg`).default}') no-repeat center center / contain;
    }
`;

export const BlueArrowDown = styled(Frame)`
    width: 12px;
    height: 12px;
    margin-left: 15px;
    background: url('${require(`../../assets/icons/arrow-right-blue.svg`).default}') no-repeat center center / contain;
    transform: rotate(90deg);
`;

export let ExportButton = (props) => {
    let { type, filter = [], exclude = []} = props;

    let { t } = useDictionary()

    if (isna(type)) {
        console.log(`ExportButton: type is not defined`)
        return `#ExportButton#`
    }

    let exportData = async (format) => {
        await FileAPI.exportData({
            format,
            type,
            filter: JSON.stringify(filter),
        });
    }

    let options = [
        { label: `.CSV`, onClick: async () => { await exportData(`csv`) } },
        { label: `.XLSX`, onClick: async () => { await exportData(`xlsx`) } },
    ].filter((item) => {
        return exclude.indexOf(item.label) === -1;
    })

    return (
        <Dropdown options={options} >
            <Button shaped background={props => props.theme.blue} extra={`padding-left: 17px; padding-right: 17px; flex-direction: row;`} >
                {t('@styled_templates_export_button')}
                <BlueArrowDown />
            </Button>
        </Dropdown>
    )
}

export const RowWrapper = styled(Frame)`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
`;

export const PageHeader = styled(RowWrapper)`

    margin-bottom: 56px;
    > * { &:first-child { margin: 25px 0; } }

    // mobile devices only
    @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 30px;
        
        > * { &:first-child { margin: 15px 0; } }
    }

    // tablts
    @media (max-width: 1024px) {
        margin-top: calc(45px);
        margin-bottom: 15px;
    }

    ${props => props.extra}
`;

/*eslint-enable*/