/*eslint-disable*/
import React, { useState, useEffect, useRef, Children } from 'react';
import styled from 'styled-components'

import { Frame, convertHex, ExportButton, RowWrapper, PageHeader } from '../ui-kit/styled-templates'

import useEventListener, { eventDispatch } from '../../hooks/useEventListener'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import useKeyHandler from '../../hooks/useKeyHandler';
import { sleep } from '../../utils/common-helper';

let PopUpWrapper = (props) => {

    let { name = ``, disableDarkOverlay = false, extra = ``, preventClosing = false, withCross = true, delayClose = 200, closeByClickOutside = true, closeEvent = () => {}} = props

    let [visible, setVisible] = useState(false)
    let [shouldRender, setShouldRender] = useState(visible)
    let ref = useRef()

    useEventListener(`OPEN_${name}_POP_UP`, async () => {
        setShouldRender(true);
        await sleep(0)
        setVisible(true);
    })
    useEventListener(`CLOSE_${name}_POP_UP`, () => {
        if (!preventClosing) {
            setVisible(false);
            setTimeout(() => {
                setShouldRender(false)
            }, delayClose)
        }
    })

    useEffect(() => {
        document.getElementsByTagName(`body`)[0].style.overflowY = visible ? `hidden` : `scroll`
    }, [visible])

    let onClose = () => { eventDispatch(`CLOSE_${name}_POP_UP`); closeEvent(); }

    if(closeByClickOutside) {
        useOnClickOutside(ref, onClose);
    }

    useKeyHandler(`Escape`, onClose)

    if (!shouldRender) {
        return null
    }

    return (
        <>
            {!disableDarkOverlay ? <DarkOverlay onClick={onClose} visible={visible} /> : null}
            <OpenProjectTab visible={visible} extra={extra} ref={ref} >
                {withCross ? <Cross onClick={onClose} /> : null}
                {/* <ChildrenWrapper> */}
                {props.children}
                {/* </ChildrenWrapper> */}
            </OpenProjectTab>
        </>
    );
}

const ChildrenWrapper = styled(Frame)`
    display: block !important;
    max-height: calc(90vh - 120px);
    /* overflow-y: scroll; */
    overflow: visible;

    // mobile devices only
    @media (min-width: 320px) and (max-width: 480px) {
        width: 100%;
    }
`;

let Cross = styled.img.attrs(() => {
    let img
    try { img = require(`../../assets/icons/pop-up-cross.svg`).default } catch (error) { }
    return ({ src: img, })
})`
    width: 24px;
    height: 24px;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 4;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
        transform: rotate(90deg);
    };
`

const OpenProjectTab = styled(Frame)`
    min-width: 350px;
    height: auto;
    padding: 30px 30px;
    border-radius: 12px;
    background: ${props => props.theme.name === `light` ? props.theme.background.primary : props.theme.background.primary};
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, ${props => props.visible ? `-50%` : `100vh`});
    z-index: 3;
    box-sizing: border-box;
    overflow: visible;

    @media only screen and (max-width: 600px) {
        min-width: auto;
        width: 90vw;
        padding: 8vw 5vw;
        transition: 0.2s;
    }

    ${props => props.extra}
`;

const DarkOverlay = styled(Frame)`
    width: 100vw;
    height: 100vh;
    background: ${props => convertHex(props.theme.text.dark, props.visible * 0.6)};
    visibility: ${props => props.visible ? `visible` : `hidden`};
    backdrop-filter: blur(${props => props.visible * props.blur * 8}px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    backdrop-filter: blur(8px);
`;

export default PopUpWrapper;
/*eslint-enable*/